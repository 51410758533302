import '../../../../pages/Admin/Admin.scss';
import '../AdminModal.scss';

import { Modal, Select, Table, TableBody, TableCell, TableHead, TableRow } from '@trackman/web-shared-components';
import { useEffect, useMemo, useState } from 'react';

import { LearningMaterialsList } from 'types/admin/learningMaterials';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as PlusIcon } from 'images/icons/PlusInCircle.svg';
import { ReactComponent as SearchIcon } from 'images/icons/Search.svg';
import { getLanguageText } from 'utilities/languages';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

type ModalProps = {
  isModalOn: boolean;
  setIsModalOn: (isModalOn: boolean) => void;
};

type RowsDisplay = {
  id: string;
  name: string;
  languages: string;
  category: string;
};

export const LearningMaterialsModal = observer(({ isModalOn, setIsModalOn }: ModalProps) => {
  const lms = useStores().learningMaterialStore;
  const sll = useStores().studyroomLessonEditorStore;
  const [searchValue, setSearchValue] = useState('');
  const [selectValue, setSelectValue] = useState('');

  useEffect(() => {
    lms.getAllLearningMaterialTypes();
  }, [lms]);

  useEffect(() => {
    lms.learningMaterialTypes.length && setSelectValue('All');
  }, [lms.learningMaterialTypes]);

  useEffect(() => {
    lms.filteredList = lms.learningMaterialsList.filter((material) =>
      material.titles
        .find((title) => title.countryCode === 'en-US')
        ?.text.toLowerCase()
        .includes(searchValue.toLowerCase())
    );
  }, [lms, searchValue]);

  useEffect(() => {
    lms.filteredList =
      selectValue !== 'All'
        ? lms.learningMaterialsList.filter((material) => material.kind.toLowerCase().includes(selectValue.toLowerCase()))
        : lms.learningMaterialsList;
  }, [lms, selectValue]);

  const rowsDisplay: RowsDisplay[] = useMemo(() => {
    let learningMaterialsToDisplay: LearningMaterialsList = lms.filteredList.filter(
      (item) => item.id && !sll.studyLesson.learningMaterialIds.includes(item.id)
    );

    return learningMaterialsToDisplay.map((learningMaterial) => {
      return {
        id: learningMaterial.id,
        name: learningMaterial.titles.find((title) => title.countryCode === 'en-US')?.text,
        languages: learningMaterial.languages ? learningMaterial.languages.map((lng) => getLanguageText(lng)).join(', ') : '',
        category: learningMaterial.kind,
      };
    }) as RowsDisplay[];
  }, [
    JSON.stringify(lms.filteredList),
    JSON.stringify(sll.studyLesson.learningMaterialIds),
    lms.filteredList,
    sll.studyLesson.learningMaterialIds,
  ]);

  return (
    <Modal
      className='admin-modal admin-content-container'
      cancelBtn={{
        label: 'Cancel',
        onClick: () => setIsModalOn(false),
      }}
      open={isModalOn}
      onClose={() => setIsModalOn(false)}
      title='Learning material list'
      titleIcon={null}
    >
      <div className='modal-body tmu-table-wrapper admin-edit-section'>
        <div className='admin-list-header'>
          <div className='text-field search-field'>
            <input
              className='search-input'
              placeholder={'Search'}
              name='search-for-learning-material'
              value={searchValue}
              onChange={(e) => setSearchValue(e.currentTarget.value)}
            />
            <SearchIcon />
          </div>
          <Select
            label='Type'
            value={selectValue ?? ''}
            className='text-field'
            onChange={(event) => setSelectValue(event.target.value as string)}
            selectOptions={lms.learningMaterialTypes.map((type) => {
              return {
                label: type.displayName === ' ' ? '' : type.displayName,
                value: type.displayName,
              };
            })}
          />
        </div>
        <Table sx={{ minWidth: 750 }} aria-labelledby='learningMaterialsTable' className='tmu-table admin-table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 350 }}>Name</TableCell>
              <TableCell sx={{ width: 400 }}>Languages</TableCell>
              <TableCell sx={{ width: 150 }}>Category</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsDisplay.length ? (
              rowsDisplay.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell>
                      <button className='row-button' onClick={() => sll.studyLesson.learningMaterialIds?.push(row.id)}>
                        <PlusIcon />
                      </button>
                      {row.name.toLowerCase()}
                    </TableCell>
                    <TableCell>{row.languages}</TableCell>
                    <TableCell>{row.category}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <Paragraph>
                    <>No leadning materials to add.</>
                  </Paragraph>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </Modal>
  );
});

export default LearningMaterialsModal;
