import './PaymentDetails.scss';

import { CardCvvElement, CardMonthElement, CardNumberElement, CardYearElement } from '@recurly/react-recurly';
import { Dispatch, SetStateAction, useEffect } from 'react';

import { ReactComponent as ErrorIcon } from 'images/icons/Error.svg';
import { PricingFormState } from 'types/purchaseMembership';
import { RecurlyError } from '@recurly/recurly-js';
import { Select } from '@trackman/web-shared-components';
import { observer } from 'mobx-react-lite';
import { trimTooManySpaces } from 'utilities/inputValidation';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type Props = {
  pricingFormState: PricingFormState;
  setPricingFormState: Dispatch<SetStateAction<PricingFormState>>;
  errorMessage: string;
  recurlyPlanError: RecurlyError | null;
  loading: boolean;
  isButtonEnabled: boolean;
};

export const PaymentDetails = observer(
  ({ pricingFormState, setPricingFormState, errorMessage, recurlyPlanError, loading, isButtonEnabled }: Props) => {
    const ps = useStores().profileStore;
    const buySubscriptionStore = useStores().buySubscriptionStore;
    const { t } = useTranslation();

    useEffect(() => {
      ps.getSignUpLists();
    }, [ps]);

    const handleInputChange = (name: string, value: string) => {
      let newValue = trimTooManySpaces(value);

      if (!newValue?.replace(/\s/g, '').length) newValue = '';
      const newState = { ...pricingFormState, [name]: newValue };
      setPricingFormState(newState);
    };

    const handleAddressInputChange = (name: string, value: string) => {
      let newValue = trimTooManySpaces(value);

      if (!newValue?.replace(/\s/g, '').length) newValue = '';
      setPricingFormState({
        ...pricingFormState,
        address: {
          ...pricingFormState.address,
          [name]: value,
        },
      });
    };

    return (
      <div className='payment-columns'>
        <div className='payment-details-column'>
          <p>{t('BillingInformation', { ns: 'billingInformation' })}</p>
          <div className='form-field'>
            <input
              placeholder={t('VAT', { ns: 'aboutCertification' })}
              name='vat-number'
              data-recurly='vat_number'
              value={pricingFormState.billingVatNumber ?? ''}
              onChange={(e) => handleInputChange('billingVatNumber', e.currentTarget.value)}
            />
            <label htmlFor='vat-number'>{t('VAT', { ns: 'aboutCertification' })}</label>
          </div>
          <div className='form-field'>
            <input
              placeholder={t('Email', { ns: 'aboutCertification' })}
              name='email'
              value={pricingFormState.address.email ?? ''}
              onChange={(e) => {
                setPricingFormState({
                  ...pricingFormState,
                  address: {
                    ...pricingFormState.address,
                    email: e.currentTarget.value,
                  },
                });
                buySubscriptionStore.subscriptionEmail = e.currentTarget.value;
              }}
            />
            <label htmlFor='email'>{t('Email', { ns: 'aboutCertification' })}</label>
          </div>
          <div className='form-field'>
            <input
              placeholder={t('FirstNameWithAsterisk', { ns: 'billingInformation' })}
              name='first-name'
              data-recurly='first_name'
              value={pricingFormState.address.first_name ?? ''}
              onChange={(e) => handleAddressInputChange('first_name', e.currentTarget.value)}
            />
            <label htmlFor='first-name'>{t('FirstNameWithAsterisk', { ns: 'billingInformation' })}</label>
          </div>

          <div className='form-field'>
            <input
              placeholder={t('LastNameWithAsterisk', { ns: 'billingInformation' })}
              name='last-name'
              data-recurly='last_name'
              value={pricingFormState.address.last_name ?? ''}
              onChange={(e) => handleAddressInputChange('last_name', e.currentTarget.value)}
            />
            <label htmlFor='last-name'>{t('LastNameWithAsterisk', { ns: 'billingInformation' })}</label>
          </div>
          <div className='form-field'>
            <input
              placeholder={t('StreetName1WithAsterisk', { ns: 'billingInformation' })}
              name='address1'
              data-recurly='address1'
              value={pricingFormState.address.address1 ?? ''}
              onChange={(e) => handleAddressInputChange('address1', e.currentTarget.value)}
            />
            <label htmlFor='address1'>{t('StreetName1WithAsterisk', { ns: 'billingInformation' })}</label>
          </div>
          <div className='form-field'>
            <input
              placeholder={t('StreetName2', { ns: 'editProfile' })}
              name='address2'
              value={pricingFormState.address.address2 ?? ''}
              onChange={(e) => handleAddressInputChange('address2', e.currentTarget.value)}
            />
            <label htmlFor='address2'>{t('StreetName2', { ns: 'editProfile' })}</label>
          </div>
          <div className='form-field'>
            <input
              placeholder={t('PostalCodeWithAsterisk', { ns: 'billingInformation' })}
              name='postal-code'
              data-recurly='postal_code'
              value={pricingFormState.billingPostalCode ?? ''}
              onChange={(e) => handleInputChange('billingPostalCode', e.currentTarget.value)}
            />
            <label htmlFor='postal-code'>{t('PostalCodeWithAsterisk', { ns: 'billingInformation' })}</label>
          </div>
          <div className='form-field'>
            <input
              placeholder={t('CityWithAsterisk', { ns: 'billingInformation' })}
              name='city'
              data-recurly='city'
              value={pricingFormState.address.city ?? ''}
              onChange={(e) => handleAddressInputChange('city', e.currentTarget.value)}
            />
            <label htmlFor='city'>{t('CityWithAsterisk', { ns: 'billingInformation' })}</label>
          </div>
          <Select
            label={t('CountryWithAsterisk', { ns: 'billingInformation' })}
            value={pricingFormState.billingCountry ?? ''}
            className='text-field'
            data-recurly='country'
            onChange={(event) => handleInputChange('billingCountry', event.target.value as string)}
            selectOptions={ps.signUpLists.Countries.map((country) => {
              return {
                label: country.name === ' ' ? t('PleaseSelectACountry', { ns: 'editProfile' }) : country.name,
                value: country.twoLetterIsoCode,
              };
            })}
          />
          <input type='hidden' data-recurly='country' value={pricingFormState.billingCountry} />
          {pricingFormState.billingCountry === 'US' && (
            <>
              <Select
                label={t('StateWithAsterisk', { ns: 'billingInformation' })}
                value={pricingFormState.address.state ?? ' ' ?? ''}
                className='text-field'
                data-recurly='state'
                onChange={(event) => {
                  setPricingFormState({
                    ...pricingFormState,
                    address: {
                      ...pricingFormState.address,
                      state: event.target.value as string,
                    },
                  });
                }}
                selectOptions={ps.signUpLists.States.map((state) => {
                  return {
                    label: state === ' ' ? t('PleaseSelectAState', { ns: 'editProfile' }) : state,
                    value: state,
                  };
                })}
              />
              <input type='hidden' data-recurly='state' value={pricingFormState.address.state} />
            </>
          )}
          {pricingFormState.billingCountry === 'CA' && <input type='hidden' data-recurly='state' value='Default' />}
          <p>{t('Discount', { ns: 'billingInformation' })}</p>
          <div className='form-field'>
            <input
              placeholder={t('DiscountCode', { ns: 'purchaseMembership' })}
              name='discount'
              data-recurly='coupon'
              value={pricingFormState.coupon ?? ''}
              onChange={(e) => {
                handleInputChange('coupon', e.currentTarget.value);
                buySubscriptionStore.discountData.couponCode = e.currentTarget.value;
              }}
            />
            <label htmlFor='discount'>{t('DiscountCode', { ns: 'purchaseMembership' })}</label>
          </div>
        </div>
        <div className='payment-details-column card-column'>
          <p>{t('CardInformation', { ns: 'billingInformation' })}</p>
          <CardNumberElement style={{ placeholder: { content: t('CardNumber', { ns: 'billingInformation' }) } }} />
          <div className='expiry-fields'>
            <CardMonthElement style={{ placeholder: { content: 'MM' } }} />
            <CardYearElement style={{ placeholder: { content: 'YY' } }} />
          </div>
          <CardCvvElement style={{ placeholder: { content: t('CVV', { ns: 'billingInformation' }) } }} />
          <div className='button-wrapper'>
            <button type='submit' disabled={!isButtonEnabled || loading || !!recurlyPlanError}>
              {t('PlaceOrder', { ns: 'aboutCertification' })}
            </button>
          </div>
          {errorMessage && (
            <p className='error-info'>
              <ErrorIcon />
              <span>{errorMessage}</span>
            </p>
          )}
        </div>
      </div>
    );
  }
);
