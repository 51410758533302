import '../AdminModal.scss';

import { AdminCourseModel, AdminCoursesList, AdminItemTitleType } from 'types/admin/courses';
import { AdminLessonModel, AdminLessonsList } from 'types/admin/lessons';
import { Modal, Table, TableBody, TableCell, TableHead, TableRow } from '@trackman/web-shared-components';

import { LanguageType } from 'types/languages';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as PlusIcon } from 'images/icons/PlusInCircle.svg';
import { getLanguageText } from 'utilities/languages';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

type ModalProps = {
  isModalOn: boolean;
  setIsModalOn: (isModalOn: boolean) => void;
  modalTitle: string;
  noDataText: string;
  dataList: AdminLessonsList | AdminCoursesList;
  dataItem: AdminLessonModel | AdminCourseModel;
};

type RowsDisplay = {
  id: string;
  title: string;
  languages: string;
};

export const PrerequisitesModal = observer(({ isModalOn, setIsModalOn, modalTitle, noDataText, dataList, dataItem }: ModalProps) => {
  const rowsDisplay: RowsDisplay[] = useMemo(() => {
    let prerequisites: AdminLessonsList | AdminCoursesList = [];
    if (dataList[0] && 'lessonIds' in dataList[0]) {
      prerequisites = (dataList as AdminCoursesList).filter((item) => item.id && !dataItem.prerequisiteIds.includes(item.id));
    } else {
      prerequisites = (dataList as AdminLessonsList).filter((item) => item.id && !dataItem.prerequisiteIds.includes(item.id));
    }
    return prerequisites.map((prerequisite) => {
      return {
        id: prerequisite.id,
        title: prerequisite.titles.find((title: AdminItemTitleType) => title.countryCode === 'en-US')?.text,
        languages: prerequisite.languages ? prerequisite.languages.map((lng: LanguageType) => getLanguageText(lng)).join(', ') : '',
      };
    }) as RowsDisplay[];
  }, [dataItem.prerequisiteIds, dataList, JSON.stringify(dataItem.prerequisiteIds), JSON.stringify(dataList)]);

  return (
    <Modal
      title={modalTitle}
      open={isModalOn}
      onClose={() => setIsModalOn(false)}
      className='admin-modal admin-content-container'
      cancelBtn={{
        label: 'Close',
        onClick: () => setIsModalOn(false),
      }}
    >
      <div className='modal-body tmu-table-wrapper admin-edit-section'>
        <Table sx={{ minWidth: 750 }} aria-labelledby='coursesTable' className='tmu-table admin-table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 500 }}>Name</TableCell>
              <TableCell sx={{ width: 500 }}>Languages</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsDisplay.length ? (
              rowsDisplay.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell>
                      <button className='row-button' onClick={() => dataItem.prerequisiteIds?.push(row.id)}>
                        <PlusIcon />
                      </button>
                      {row.title.toLowerCase()}
                    </TableCell>
                    <TableCell>{row.languages}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <Paragraph>
                    <>{noDataText}</>
                  </Paragraph>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </Modal>
  );
});

export default PrerequisitesModal;
