import '../AdminModal.scss';

import { Modal } from '@trackman/web-shared-components';
import { observer } from 'mobx-react-lite';

type Props = {
  isModalOn: boolean;
  setIsModalOn: (isModalOn: boolean) => void;
  jsonPreview: string;
};

export const LessonPreviewModal = observer(({ isModalOn, setIsModalOn, jsonPreview }: Props) => {
  return (
    <Modal
      title='Lesson preview'
      cancelBtn={{
        label: 'Cancel',
        onClick: () => setIsModalOn(false),
      }}
      open={isModalOn}
      onClose={() => setIsModalOn(false)}
      className='admin-info-modal preview-modal'
    >
      <div className='modal-body'>
        <div className='modal-json-wrapper'>
          <pre>{jsonPreview}</pre>
        </div>
      </div>
    </Modal>
  );
});

export default LessonPreviewModal;
