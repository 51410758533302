import '../../../pages/Admin/Courses/EditCourse/EditCourse.scss';

import { AdminCourseModel } from 'types/admin/courses';
import Tooltip from '@trackman/web-shared-components/components/Tooltip/Tooltip';
import { observer } from 'mobx-react-lite';

type Props = {
  dataItem: AdminCourseModel;
};

const colors = [
  {
    hex: '#009688',
    name: 'Mint',
  },
  {
    hex: '#FF2D55',
    name: 'Light red',
  },
  {
    hex: '#9C27B0',
    name: 'Purple',
  },
  {
    hex: '#FE9800',
    name: 'Amber',
  },
  {
    hex: '#4CAF50',
    name: 'Light green',
  },
  {
    hex: '#714333',
    name: 'Brown',
  },
] as const;

export const HighlightColor = observer(({ dataItem }: Props) => {
  return (
    <div className='admin-edit-section'>
      <h2>Highlight Color</h2>
      <div className='colors-container'>
        {colors.map((color) => {
          return (
            <Tooltip key={color.name} title={color.name}>
              <div className={`color-box ${dataItem.color === color.hex ? 'selected' : ''}`} onClick={() => (dataItem.color = color.hex)}>
                <div
                  className='color-option'
                  style={{
                    boxShadow: dataItem.color === color.hex ? `${color.hex} 0px 0px 0px 3px inset` : `${color.hex} 0px 0px 0px 16px inset`,
                  }}
                ></div>
              </div>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
});

export default HighlightColor;
