import '../../../../pages/Organization/Organization.scss';

import { Modal } from '@trackman/web-shared-components';
import { ReactComponent as PlusIcon } from 'images/icons/Plus.svg';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const AddNewClassModal = observer(() => {
  const { t } = useTranslation();
  const cs = useStores().classesStore;

  return (
    <Modal
      open={cs.isAddClassModalOpen}
      onClose={() => (cs.isAddClassModalOpen = false)}
      cancelBtn={{
        label: t('Cancel', { ns: 'general' }),
        onClick: () => (cs.isAddClassModalOpen = false),
        className: 'styled-button secondary',
      }}
      confirmBtn={{
        label: t('Create', { ns: 'organizationPage' }),
        onClick: () => {
          cs.createNewClassAndUpdate();
          cs.isAddClassModalOpen = false;
          cs.newClass.name = '';
        },
        disabled: cs.newClass.name === '' || cs.isCreatingNewClass,
      }}
      className='organization-modal'
      title={t('CreateNewClass', { ns: 'organizationPage' })}
      titleIcon={<PlusIcon />}
    >
      <div className='modal-body'>
        <p className='name-your-class'>
          <strong>{t('NameYourClass', { ns: 'organizationPage' })}</strong>
        </p>
        <ProfileInput
          label={t('ClassName', { ns: 'organizationPage' })}
          value={cs.newClass.name ?? ''}
          onChange={(newValue) => (cs.newClass.name = newValue)}
        />
      </div>
    </Modal>
  );
});

export default AddNewClassModal;
