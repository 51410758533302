import './FullCalculator.scss';

import { Fragment, useEffect, useState } from 'react';

import CalculactorSelect from './Select/Select';
import { CalculatorKind } from 'types/learningMaterials';
import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import Chart from './Chart/Chart';
import OutputTable from './OutputTable/OutputTable';
import SavedTable from './SavedTable/SavedTable';
import Slider from './Slider/Slider';
import { observer } from 'mobx-react-lite';
import useCalcResult from './hooks/useCalcResult';
import useGetVariables from './hooks/useGetVariables';

type Props = {
  initialCalculatorOptions: CalculatorOptions;
  calcKind: CalculatorKind;
  htmlColor: string;
};

const FullCalculator = observer(({ initialCalculatorOptions, calcKind, htmlColor }: Props) => {
  const [calcOptions, setCalcOptions] = useState(initialCalculatorOptions);

  useEffect(() => {
    setCalcOptions(initialCalculatorOptions);
  }, [initialCalculatorOptions]);

  const { calcResultWithKind } = useCalcResult(calcOptions, calcKind);

  const { sliderVariablesInThisCalc, selectVariablesInThisCalc } = useGetVariables(calcOptions);

  return (
    <div className='FullCalculator-container'>
      <Chart calcOptions={calcOptions} calcResultWithKind={calcResultWithKind} />

      <div className='selects-and-sliders-container'>
        <div className='calc-select-container'>
          {selectVariablesInThisCalc.map((selectVariable) => (
            <Fragment key={selectVariable}>
              <CalculactorSelect calcOptions={calcOptions} setCalcOptions={setCalcOptions} selectVariable={selectVariable} />
            </Fragment>
          ))}
        </div>

        <div className='calc-slider-container'>
          {sliderVariablesInThisCalc.map((sliderVariable) => (
            <Fragment key={sliderVariable}>
              <Slider calcOptions={calcOptions} setCalcOptions={setCalcOptions} sliderVariable={sliderVariable} htmlColor={htmlColor} />
            </Fragment>
          ))}
        </div>
      </div>

      <OutputTable calcOptions={calcOptions} calcResult={calcResultWithKind.calcResult} htmlColor={htmlColor} />
      <SavedTable />
    </div>
  );
});

export default FullCalculator;
