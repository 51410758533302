import '../AdminModal.scss';

import { Modal } from '@trackman/web-shared-components';
import Paragraph from 'components/TextComps/Paragraph';
import { observer } from 'mobx-react-lite';

type Props = {
  isModalOn: boolean;
  setIsModalOn: (isModalOn: boolean) => void;
  deleteAction: () => void;
  itemName: string;
};

export const DeleteListItemModal = observer(({ isModalOn, setIsModalOn, deleteAction, itemName }: Props) => {
  return (
    <Modal
      cancelBtn={{
        label: 'Cancel',
        onClick: () => setIsModalOn(false),
      }}
      confirmBtn={{
        label: 'Delete',
        onClick: () => deleteAction(),
      }}
      onClose={() => setIsModalOn(false)}
      title={`Delete ${itemName}`}
      titleIcon={null}
      open={isModalOn}
    >
      <div className='modal-body'>
        <Paragraph>
          <>Deleting this {itemName} cannot be undone.</>
        </Paragraph>
        <Paragraph>
          <>Are you sure you want to permanently delete this {itemName}?</>
        </Paragraph>
      </div>
    </Modal>
  );
});

export default DeleteListItemModal;
