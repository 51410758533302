import '../../../../pages/Admin/Admin.scss';
import '../AdminModal.scss';
import './ImageGalleryModal.scss';

import { Button, Loader, Modal } from '@trackman/web-shared-components';
import { useCallback, useRef } from 'react';

import { LanguageType } from 'types/languages';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as PlusIcon } from 'images/icons/PlusInCircle.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

type ModalProps = {
  isModalOn: boolean;
  setIsModalOn: (isModalOn: boolean) => void;
  countryCode: LanguageType;
};

const mediaFolderPaths = ['TMU', 'Icons', 'Clubs', 'Wind', 'no_title_750px', 'with_title_750px', 'with_tmu_logo_750px'];

export const ImageGalleryModal = observer(({ isModalOn, setIsModalOn, countryCode }: ModalProps) => {
  const lms = useStores().learningMaterialStore;
  const ms = useStores().mediaStore;
  const inputPic = useRef<HTMLInputElement>(null);

  const uploadNewImages = useCallback(() => {
    if (inputPic.current?.files?.length) {
      ms.newImagesToUpload = Array.from(inputPic.current.files).map((file) => ({
        id: '',
        originalFilename: file.name,
        folderPath: `/${ms.selectedFolderPath}`,
        fileUrl: file.name,
        mimeType: '',
        dataForSmallFiles: '',
        data: file,
      }));

      ms.processLoadedImagesAndUpdateImagesList();
    }
  }, [inputPic, ms]);

  return (
    <Modal
      cancelBtn={{
        label: 'Close',
        onClick: () => setIsModalOn(false),
      }}
      onClose={() => setIsModalOn(false)}
      title='Image gallery'
      titleIcon={null}
      open={isModalOn}
      className='admin-modal admin-gallery-modal'
    >
      <div className='modal-body image-gallery-wrapper'>
        <div className='folders-section'>
          <Paragraph>
            <>Choose a folder:</>
          </Paragraph>
          <ul className='folders-list'>
            {mediaFolderPaths.map((path) => (
              <li key={path} className='folder-item'>
                <button
                  className={`${ms.selectedFolderPath === path ? 'active' : ''}`}
                  onClick={() => {
                    ms.selectedFolderPath = path;
                    ms.getMediaInSelectedFolder();
                  }}
                >
                  /{path}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className='images-section'>
          <Paragraph>
            <>Media</>
          </Paragraph>
          <div className='upload-container'>
            <input
              type='file'
              id='file'
              accept='image/*'
              multiple={true}
              ref={inputPic}
              style={{ display: 'none' }}
              onChange={uploadNewImages}
            />
            <Button
              className='styled-button secondary'
              label='Upload new file(s)'
              intent='secondary'
              onClick={() => inputPic.current?.click()}
            />
          </div>
          <div className='images-container'>
            {ms.isLoading ? (
              <div className='loader-wrapper'>
                <Loader size='large' />
              </div>
            ) : (
              <>
                {ms.imagesInSelectedFolder.map((image) => (
                  <div key={image.id} className='image-wrapper'>
                    <img src={image.fileUrl} alt={image.originalFilename} />
                    <div
                      className='image-overlay'
                      onClick={() => {
                        let index = lms.learningMaterial.medias.findIndex((media) => media.countryCode === countryCode);
                        if (index > -1) {
                          lms.learningMaterial.medias.find((media) => media.countryCode === countryCode && (media.mediaId = image.id));
                        } else {
                          lms.learningMaterial.medias.push({
                            mediaId: image.id,
                            countryCode: countryCode,
                          });
                        }
                        setIsModalOn(false);
                      }}
                    >
                      <PlusIcon />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default ImageGalleryModal;
