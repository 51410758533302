import { AdminParameterPrimaryCategory } from 'types/admin/trackmanParameters';
import { Select } from '@trackman/web-shared-components';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

const parameterPrimaryCategories: AdminParameterPrimaryCategory[] = ['Tee-to-green', 'Putting'];

export const ParameterPrimaryCategory = observer(() => {
  const ps = useStores().parametersStore;

  return (
    <div className='admin-edit-section'>
      <h2>Parameter primary category</h2>
      <Select
        label='Primary category'
        className='text-field'
        value={ps.parameter.primaryCategory ?? ''}
        onChange={(event) => {
          if (parameterPrimaryCategories.includes(event.target.value as AdminParameterPrimaryCategory)) {
            ps.parameter.primaryCategory = event.target.value as AdminParameterPrimaryCategory;
          }
        }}
        selectOptions={parameterPrimaryCategories.map((primaryCategory) => {
          return {
            label: primaryCategory ? primaryCategory : '',
            value: primaryCategory,
          };
        })}
      />
    </div>
  );
});

export default ParameterPrimaryCategory;
