import './TextComps.scss';

type Props = {
  children: React.ReactElement;
  className?: string;
};

export const Paragraph = ({ children, className }: Props) => {
  return <p className={`tm-paragraph ${className ? className : ''}`}>{children}</p>;
};

export default Paragraph;
