import './CertificateTab.scss';

import { useEffect, useState } from 'react';

import CertificateTile from 'components/CertificateTile/CertificateTile';
import { Modal } from '@trackman/web-shared-components';
import Paragraph from 'components/TextComps/Paragraph';
import SectionTitle from 'components/TextComps/SectionTitle';
import Skeleton from '@trackman/web-shared-components/components/LoadingPlaceholder/Skeleton';
import certExample from 'images/Certification/cert_example.jpg';
import certLevel1Icon from 'images/Certification/cert_lvl_1.png';
import certLevel2Icon from 'images/Certification/cert_lvl_2.png';
import certMasterIcon from 'images/Certification/cert_lvl_master.png';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const CertificateTab = observer(() => {
  const { t } = useTranslation();
  const cs = useStores().certificateStore;
  const [isCertPreviewOn, setCertPreviewOn] = useState(false);

  useEffect(() => {
    cs.checkAvailableCertificates();
  }, [cs]);

  const isUserCertified =
    cs.availableCertificates['tmu-Level1'] || cs.availableCertificates['tmu-Level2'] || cs.availableCertificates['tmu-Master'];

  return (
    <div className='certification-tab'>
      <SectionTitle>
        <>{t('TrackmanCertificate', { ns: 'aboutCertification' })}</>
      </SectionTitle>

      {cs.isAvailableCertsLoading ? (
        <Skeleton variant='text' width={400} sx={{ height: '18px' }} />
      ) : !cs.isAvailableCertsLoading && !isUserCertified ? (
        <Paragraph>
          <>
            {t('WhenYouAreDoneWithACertificateProgram', { ns: 'certificate' })}{' '}
            <strong className='link' onClick={() => setCertPreviewOn(true)}>
              {t('Preview', { ns: 'certificate' })}
            </strong>
          </>
        </Paragraph>
      ) : (
        <Paragraph>
          <>{t('HereYouCanDowload', { ns: 'certificate' })}</>
        </Paragraph>
      )}

      <Modal
        open={isCertPreviewOn}
        onClose={() => setCertPreviewOn(false)}
        className='cert-example-modal-wrapper'
        title={t('CertificatePreview', { ns: 'certificate' })}
      >
        <div className='modal-body'>
          <img src={certExample} alt={t('CertificateExample', { ns: 'aboutCertification' })} className='cert-example' />
        </div>
      </Modal>
      <div className='cerfitications-wrapper'>
        <CertificateTile
          subtitle={t('ProfessionalLevel', { level: 1, ns: 'certificate' })}
          altText={t('CertificateLevelBadgeAltText', { level: 1, ns: 'certificate' })}
          icon={certLevel1Icon}
          level='level1'
          showLoader={cs.isLoading === 'level1'}
          hasCertificate={cs.availableCertificates['tmu-Level1']}
        />
        <CertificateTile
          subtitle={t('ProfessionalLevel', { level: 2, ns: 'certificate' })}
          altText={t('CertificateLevelBadgeAltText', { level: 2, ns: 'certificate' })}
          icon={certLevel2Icon}
          level='level2'
          showLoader={cs.isLoading === 'level2'}
          hasCertificate={cs.availableCertificates['tmu-Level2']}
        />
        <CertificateTile
          subtitle={t('Master', { ns: 'aboutCertification' })}
          altText={t('CertificateLevelBadgeAltText', { level: 'master', ns: 'certificate' })}
          icon={certMasterIcon}
          level='master'
          showLoader={cs.isLoading === 'master'}
          hasCertificate={cs.availableCertificates['tmu-Master']}
        />
      </div>
    </div>
  );
});

export default CertificateTab;
