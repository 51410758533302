import './PartnerCard.scss';

import Skeleton from '@trackman/web-shared-components/components/LoadingPlaceholder/Skeleton';
import { observer } from 'mobx-react-lite';

export type PartnerCardProps =
  | {
      profileUrl: string;
      name: string;
      description: string;
      delay?: number;
      skeleton?: false;
    }
  | {
      skeleton: true;
      delay?: number;
    };

export const PartnerCard = observer((props: PartnerCardProps) => {
  return (
    <div className='partner-card' style={{ animationDelay: `${props.delay}ms` }}>
      {props.skeleton ? (
        <Skeleton variant='rectangular' width={100} height={100} sx={{ margin: '5px auto' }} />
      ) : (
        <img className='partner-card-img' src={props.profileUrl} alt={`${props.name}`} />
      )}
      <div className='partner-card-info'>
        {props.skeleton ? (
          <Skeleton variant='text' width={120} sx={{ margin: '5px auto' }} />
        ) : (
          <div className='partner-card-name'>{props.name}</div>
        )}
        {props.skeleton ? (
          <>
            <Skeleton variant='text' width={200} sx={{ margin: '5px auto' }} />
            <Skeleton variant='text' width={140} sx={{ margin: '5px auto' }} />
          </>
        ) : (
          <div className='partner-card-description'>{props.description}</div>
        )}
      </div>
    </div>
  );
});

export default PartnerCard;
