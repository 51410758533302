import { AdminCourseModel } from 'types/admin/courses';
import { AdminLessonModel } from 'types/admin/lessons';
import { Checkbox } from '@trackman/web-shared-components';
import { observer } from 'mobx-react-lite';

type Props = {
  dataItem: AdminLessonModel | AdminCourseModel;
};

export const CertificationSetting = observer(({ dataItem }: Props) => {
  return (
    <Checkbox
      label='Certification'
      reversed
      onChange={() => (dataItem.certification = !dataItem.certification)}
      checked={dataItem.certification}
    />
  );
});

export default CertificationSetting;
