import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@trackman/web-shared-components';
import { useCallback, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import { handleSearchStudent } from '../../OrganizationSearchbar/SearchForStudents/search-helper';
import nanoid from 'nanoid';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type DynamicRows = {
  id: string;
  initials: string;
  name: string;
  [key: string]: string;
};

export const StudentProgress = observer(() => {
  const { t } = useTranslation();
  const gps = useStores().gameProgressStore;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    gps.isSearchForStudentsVisible = true;
  }, [gps]);

  useEffect(() => {
    gps.filteredList = handleSearchStudent(gps.searchStudentInput, gps.classProgressForSelectedGame);
  }, [gps, gps.searchStudentInput]);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const rows = useMemo((): DynamicRows[] => {
    let dynamicRows: DynamicRows[] = [];

    gps.filteredList.map((student, index) => {
      const initials = student.name
        .split(' ')
        .map((a) => a[0])
        .join('');

      dynamicRows.push({
        id: student.id,
        initials: initials,
        name: student.name,
      });

      student.progressList?.map((lesson) => {
        return (dynamicRows[index][lesson.lessonName] = `${Math.round(lesson.percentage * 100)}%`);
      });

      return dynamicRows;
    });

    return dynamicRows;
  }, [gps.filteredList]);

  return (
    <div className='tmu-table-wrapper'>
      <Table sx={{ minWidth: 500 }} className='class-progress-table'>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 50 }}></TableCell>
            <TableCell sx={{ width: 200 }}>{t('Name', { ns: 'studentsPage' })}</TableCell>
            {gps.filteredList[0]?.progressList?.map((lesson) => (
              <TableCell key={nanoid()} sx={{ width: 80 }} className='lesson-progress'>
                {lesson.lessonName.toLowerCase()}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row) => (
            <TableRow key={nanoid()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell className='initials-cell' sx={{ width: 50 }}>
                <div>{row.initials}</div>
              </TableCell>
              <TableCell sx={{ width: 200 }}>{row.name}</TableCell>
              {gps.filteredList[0]?.progressList?.map((lesson) => {
                const generateClassName = (): string => {
                  if (row[lesson.lessonName] == null) return '';

                  return clsx('lesson-progress', {
                    gameFinished: row[lesson.lessonName] === '100%',
                    gameNotStarted: row[lesson.lessonName] === '0%',
                    gameInProgress: row[lesson.lessonName] !== '0%' && row[lesson.lessonName] !== '100%',
                  });
                };
                return (
                  <TableCell key={nanoid()} sx={{ width: 80 }} className={generateClassName()}>
                    <div>{row[lesson.lessonName]}</div>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={5}
              count={rows.length}
              page={page}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={t('RowsPerPage', { ns: 'general' })}
              onPageChange={(e, newPage: number) => setPage(newPage)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
});

export default StudentProgress;
