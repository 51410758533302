import './EditOrganization.scss';

import { useCallback, useEffect, useState } from 'react';

import { DefaultRoutes } from 'Routes';
import DeleteListItemModal from 'components/Admin/Modals/DeleteListItemModal/DeleteListItemModal';
import InstructorsTable from 'components/Admin/InstructorsTable/InstructorsTable';
import OrganizationDetails from 'components/Admin/OrganizationDetails/OrganizationDetails';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

export const EditOrganization = observer(() => {
  useSetPageTitle({ title: 'Edit organization', subtitle: '' });

  const [isModalOn, setIsModalOn] = useState(false);
  const os = useStores().organizationsEditorStore;
  const { organizationId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (organizationId) {
      os.getOrganization(organizationId);
    }
    return () => {
      os.organization = { ...os.cleanOrganization };
    };
  }, [os, organizationId]);

  const saveOrganization = () => {
    if (organizationId) {
      os.saveEditedOrgAndUpdateOrgs();
    } else {
      os.saveNewOrgAndUpdateOrg();
    }
  };

  const deleteOrganization = useCallback(() => {
    os.deleteOrganizationAndUpdate();
    setIsModalOn(false);
    navigate(`/Admin/${DefaultRoutes.OrganizationsList}`);
  }, [os, setIsModalOn, navigate]);

  return (
    <div className='admin-content-container organization-editor-container'>
      <OrganizationDetails />
      <InstructorsTable />
      <div className='admin-edit-section buttons-group'>
        <button className='styled-button secondary' onClick={() => (os.organization.disabled = !os.organization.disabled)}>
          {os.organization.disabled ? 'Activate organization' : 'Disable organization'}
        </button>
        {organizationId ? (
          <button
            className='styled-button danger'
            onClick={() => {
              os.selectedOrganizationId = organizationId;
              setIsModalOn(true);
            }}
          >
            Delete organization
          </button>
        ) : (
          <></>
        )}
      </div>
      <div className='admin-footer'>
        <button className='styled-button primary' onClick={() => saveOrganization()} disabled={os.organization.name === ''}>
          Save changes
        </button>
      </div>
      {organizationId && isModalOn ? (
        <DeleteListItemModal isModalOn={isModalOn} setIsModalOn={setIsModalOn} deleteAction={deleteOrganization} itemName='organization' />
      ) : (
        <></>
      )}
    </div>
  );
});

export default EditOrganization;
