import { Checkbox, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@trackman/web-shared-components';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ClassesTableRows } from 'types/organization/organization';
import DeleteInvitationsModal from 'components/Organization/Modals/DeleteInvitationsModal/DeleteInvitationsModal';
import { Invites } from 'types/organization/invites';
import { ReactComponent as SendIcon } from 'images/icons/Send.svg';
import { ReactComponent as TrashIcon } from 'images/icons/Trash.svg';
import { filterUsersByClass } from 'utilities/organizationDataFilter';
import { handleSearchStudent } from 'components/Organization/OrganizationSearchbar/SearchForStudents/search-helper';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const PendingInvites = observer(() => {
  const { t } = useTranslation();
  const iss = useStores().inviteStudentsStore;
  const studentsStore = useStores().studentsStore;
  const cs = useStores().classesStore;

  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isDeleteModalOn, setIsDeleteModalOn] = useState(false);

  useEffect(() => {
    studentsStore.isSeeStudentsTabActive = false;
    iss.isPendingInvitesTabActive = true;
  }, [studentsStore, iss]);

  useEffect(() => {
    if (iss.searchInput !== '') {
      iss.filteredList = handleSearchStudent(iss.searchInput, iss.filteredList);
    } else {
      iss.filteredList = filterUsersByClass(iss.invites, cs.selectedClassId) as Invites;
    }
  }, [iss, iss.searchInput, cs.selectedClassId]);

  useEffect(() => {
    iss.filteredList = filterUsersByClass(iss.invites, cs.selectedClassId) as Invites;
  }, [cs.selectedClassId, iss]);

  const rows = useMemo((): ClassesTableRows[] => {
    let dynamicRows: ClassesTableRows[] = [];

    iss.filteredList.map((invite) => {
      const initials = invite.name
        ? invite.name
            .split(' ')
            .map((a) => a[0])
            .join('')
        : 'N/A';

      dynamicRows.push({
        id: invite.id,
        initials: initials,
        name: invite.name || '',
        classes: invite.classes ? invite.classes.map((classItem) => classItem.name).join(', ') : '',
        email: invite.email,
      });

      return dynamicRows;
    });

    return dynamicRows;
  }, [iss.filteredList]);

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        const newSelectedRowIds = rows.map((n) => n.id);
        setSelectedRowIds(newSelectedRowIds);
        return;
      }
      setSelectedRowIds([]);
    },
    [rows]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<unknown>, id: string) => {
      const selectedIndex = selectedRowIds.indexOf(id);
      let newSelectedRowIds: string[] = [];

      if (selectedIndex === -1) {
        newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds, id);
      } else if (selectedIndex === 0) {
        newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds.slice(1));
      } else if (selectedIndex === selectedRowIds.length - 1) {
        newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds.slice(0, selectedIndex), selectedRowIds.slice(selectedIndex + 1));
      }
      setSelectedRowIds(newSelectedRowIds);
    },
    [selectedRowIds]
  );

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const isSelected = useCallback(
    (id: string) => {
      return selectedRowIds.indexOf(id) !== -1;
    },
    [selectedRowIds]
  );

  const resendInvitation = useCallback(() => {
    selectedRowIds.map((id) => {
      iss.resendInvitation(id);
      return true;
    });
  }, [iss, selectedRowIds]);

  return (
    <div className='tmu-table-wrapper'>
      <div className='table-subheading'>
        <strong>{t('PendingInvites', { ns: 'studentsPage', number: iss.filteredList.length })}</strong>
        <div className='subheading-buttons'>
          <button className='styled-button secondary' disabled={selectedRowIds.length < 1} onClick={() => resendInvitation()}>
            <SendIcon />
            <span>{t('ResendInvitations', { ns: 'studentsPage' })}</span>
          </button>
          <button className='styled-button secondary' disabled={selectedRowIds.length < 1} onClick={() => setIsDeleteModalOn(true)}>
            <TrashIcon />
            <span>{t('DeleteInvitations', { ns: 'studentsPage' })}</span>
          </button>
        </div>
      </div>
      <Table sx={{ minWidth: 750 }} aria-labelledby='invitesTable'>
        <TableHead>
          <TableRow>
            <TableCell padding='checkbox'>
              <Checkbox
                color='primary'
                indeterminate={selectedRowIds.length > 0 && selectedRowIds.length < rows.length}
                checked={rows.length > 0 && selectedRowIds.length === rows.length}
                onChange={(e) => handleSelectAllClick(e)}
                label={undefined}
              />
            </TableCell>
            <TableCell sx={{ width: 50 }}></TableCell>
            <TableCell sx={{ width: 250 }}>{t('Name', { ns: 'studentsPage' })}</TableCell>
            <TableCell sx={{ width: 250 }}>{t('Class', { ns: 'studentsPage' })}</TableCell>
            <TableCell sx={{ width: 250 }}>{t('Email', { ns: 'studentsPage' })}</TableCell>
            <TableCell sx={{ width: 100 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row) => {
            const isItemSelected = isSelected(row.id);

            return (
              <TableRow
                hover
                onClick={(event) => handleClick(event, row.id)}
                role='checkbox'
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell padding='checkbox'>
                  <Checkbox color='primary' checked={isItemSelected} label={undefined} />
                </TableCell>
                <TableCell className='initials-cell'>
                  <div>{row.initials}</div>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.classes}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell className='buttons-cell'>
                  <button
                    className='row-button'
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedRowIds([row.id]);
                      resendInvitation();
                    }}
                  >
                    <SendIcon />
                  </button>
                  <button
                    className='row-button'
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedRowIds([row.id]);
                      setIsDeleteModalOn(true);
                    }}
                  >
                    <TrashIcon />
                  </button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(e, newPage: number) => setPage(newPage)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <DeleteInvitationsModal isModalOn={isDeleteModalOn} setIsModalOn={setIsDeleteModalOn} selected={selectedRowIds} />
    </div>
  );
});

export default PendingInvites;
