import '../../../../pages/Admin/Admin.scss';
import '../AdminModal.scss';

import FullCalculator from 'components/FullCalculator/FullCalculator';
import { Modal } from '@trackman/web-shared-components';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useStores } from 'index';

type ModalProps = {
  isModalOn: boolean;
  setIsModalOn: (isModalOn: boolean) => void;
};

export const CalculatorPreviewModal = observer(({ isModalOn, setIsModalOn }: ModalProps) => {
  const lms = useStores().learningMaterialStore;

  const closeModal = useCallback(() => {
    localStorage.removeItem('calculatorPreviewCulture');
    setIsModalOn(false);
  }, [setIsModalOn]);

  return (
    <Modal
      cancelBtn={{
        label: 'Close',
        onClick: () => closeModal(),
      }}
      onClose={() => setIsModalOn(false)}
      title='Calculator Preview'
      titleIcon={null}
      open={isModalOn}
      className='admin-modal admin-calculator-preview-modal'
    >
      <div className='modal-body'>
        <FullCalculator
          initialCalculatorOptions={JSON.parse(lms.learningMaterial?.calculatorOptions)}
          calcKind={lms.learningMaterial?.calculatorKind}
          htmlColor='#467d80'
        />
      </div>
    </Modal>
  );
});

export default CalculatorPreviewModal;
