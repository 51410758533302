import './CardDetailsModal.scss';

import { CardElement, Elements, RecurlyProvider, useRecurly } from '@recurly/react-recurly';
import { useCallback, useRef, useState } from 'react';

import { ReactComponent as ErrorIcon } from 'images/icons/Error.svg';
import { Modal } from '@trackman/web-shared-components';
import { RecurlyError } from '@recurly/recurly-js';
import { observer } from 'mobx-react-lite';
import { trimTooManySpaces } from 'utilities/inputValidation';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type CardDetailsProps = {
  isModalOn: boolean;
  setIsModalOn: (arg0: boolean) => void;
};

export const CardDetailsModal = observer(({ isModalOn, setIsModalOn }: CardDetailsProps) => {
  return (
    <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY}>
      <Elements>
        <CardDetails isModalOn={isModalOn} setIsModalOn={setIsModalOn} />
      </Elements>
    </RecurlyProvider>
  );
});

const CardDetails = observer(({ isModalOn, setIsModalOn }: CardDetailsProps) => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const bs = useStores().billingStore;
  const { t } = useTranslation();

  const formRef = useRef<HTMLFormElement>(null);
  const recurly = useRecurly();

  const handleThreeDSecure = useCallback(
    (cardTokenId: string, actionTokenId: string) => {
      const risk = recurly.Risk();
      const threeDSecure = risk.ThreeDSecure({ actionTokenId: actionTokenId });

      threeDSecure.on('token', (actionToken) => bs.sendThreeDSecureRequest(cardTokenId, actionToken.id).then(() => setIsModalOn(false)));
      threeDSecure.attach(document.body);
      setIsButtonEnabled(true);
    },
    [bs, recurly, setIsModalOn]
  );

  const onRecurlyValidationError = (err: RecurlyError) => {
    if (err.fields) {
      setErrorMessage(`${err.message} The following fields appear to be invalid: ${err.fields.join(', ')}`);
    } else if (err && err.message) {
      setErrorMessage(err.message);
    }
  };

  const handleSubmit = useCallback(() => {
    setIsButtonEnabled(false);

    formRef.current &&
      recurly.token(formRef.current, (err, token) => {
        if (err) {
          onRecurlyValidationError(err);
          setIsButtonEnabled(true);
        } else {
          bs.updateCreditCard(token.id).then((data) => {
            //if 406 is returned it means that 3D secure handling is required and we need to save params
            if (data && data.status === 406) {
              //threeDSecureActionTokenId is needed to start 3D Secure challenge
              let actionTokenId = data.data;
              handleThreeDSecure(token.id, actionTokenId);
            } else if (data && data.status === 200) {
              setIsModalOn(false);
              setIsButtonEnabled(true);
            } else {
              data && setErrorMessage(data.data);
            }
          });
        }
      });
  }, [bs, handleThreeDSecure, recurly, setIsModalOn]);

  return (
    <Modal
      open={isModalOn}
      onClose={() => setIsModalOn(false)}
      className='profile-modal edit-payment-method-modal'
      title={t('ChangeCreditCard', { ns: 'billingInformation' })}
      cancelBtn={{
        label: t('Cancel', { ns: 'general' }),
        onClick: () => setIsModalOn(false),
        className: 'styled-button secondary',
      }}
      confirmBtn={{
        label: t('SaveChanges', { ns: 'editProfile' }),
        onClick: () => handleSubmit(),
        disabled: !isButtonEnabled,
      }}
    >
      <div className='modal-body'>
        <div className='profile-inputs-column'>
          <h3 className='section-subtitle'>{t('CardInformation', { ns: 'billingInformation' })}</h3>
          <form ref={formRef}>
            <div className='name-fields'>
              <input
                className='card-form-input'
                data-recurly='first_name'
                placeholder={t('FirstName', { ns: 'aboutCertification' })}
                value={bs.CCInfoUpdate.FirstName ?? ''}
                onChange={(e) => {
                  bs.CCInfoUpdate.FirstName = trimTooManySpaces(e.target.value);
                  if (!e.target.value.replace(/\s/g, '').length) bs.CCInfoUpdate.FirstName = '';
                  setIsButtonEnabled(!!bs.CCInfoUpdate.FirstName);
                }}
              />
              <input
                className='card-form-input'
                data-recurly='last_name'
                placeholder={t('LastName', { ns: 'aboutCertification' })}
                value={bs.billingInfoUpdate.LastName ?? ''}
                onChange={(e) => {
                  bs.billingInfoUpdate.LastName = trimTooManySpaces(e.target.value);
                  if (!e.target.value.replace(/\s/g, '').length) bs.billingInfoUpdate.LastName = '';
                  setIsButtonEnabled(!!bs.billingInfoUpdate.LastName);
                }}
              />
            </div>
            <input type='hidden' data-recurly='address1' value={bs.billingInfo.address.street1} />
            <input type='hidden' data-recurly='postal_code' value={bs.billingInfo.address.postal_code} />
            <input type='hidden' data-recurly='city' value={bs.billingInfo.address.city} />
            <input type='hidden' data-recurly='country' value={bs.billingInfo.address.country} />
            {bs.billingInfo.address.country === 'US' && <input type='hidden' data-recurly='state' value={bs.billingInfo.address.region} />}
            {bs.billingInfo.address.country === 'CA' && <input type='hidden' data-recurly='state' value='Default' />}
            <input type='hidden' name='recurly-token' data-recurly='token' />
            <CardElement
              style={{
                placeholder: {
                  content: {
                    cvv: t('CVV', { ns: 'billingInformation' }),
                    expiry: t('MM/YY', { ns: 'billingInformation' }),
                    number: t('CardNumber', { ns: 'billingInformation' }),
                  },
                },
              }}
            />
            {errorMessage && (
              <p className='error-info'>
                <ErrorIcon />
                <span>{errorMessage}</span>
              </p>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
});

export default CardDetailsModal;
