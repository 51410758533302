import './SideMenu.scss';

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as Adjustment } from '../../images/icons/menu/Adjustment.svg';
import AdminSubmenu from './Submenus/AdminSubmenu';
import { ReactComponent as BallTotal } from '../../images/icons/menu/BallTotal.svg';
import { CountryFlag } from 'utilities/CountryFlag';
import { DefaultRoutes } from 'Routes';
import { ReactComponent as Document } from '../../images/icons/menu/Document.svg';
import Hamburger from 'components/Hamburger/Hamburger';
import { ReactComponent as Home } from '../../images/icons/menu/Home.svg';
import LanguageSubmenu from './Submenus/LanguageSubmenu';
import { ReactComponent as League } from '../../images/icons/menu/League.svg';
import { ReactComponent as Medal } from '../../images/icons/menu/Medal.svg';
import MenuItem from 'components/MenuItem/MenuItem';
import { ReactComponent as Parameters } from '../../images/icons/menu/Parameters.svg';
import { ReactComponent as Profile } from '../../images/icons/menu/Profile.svg';
import ProfileSubmenu from './Submenus/ProfileSubmenu';
import { ReactComponent as Radar } from '../../images/icons/menu/Radar.svg';
import { ReactComponent as SpeechBubble } from '../../images/icons/menu/Speech_Bubble.svg';
import { ReactComponent as Trophy } from '../../images/icons/menu/Trophy.svg';
import { ReactComponent as TwoPersons } from '../../images/icons/menu/TwoPersons.svg';
import { getLanguageText } from '../../utilities/languages';
import { getUsername } from 'utilities/userInfo';
import logo from '../../images/MainMenu/Trackman.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

declare var _urq: Array<any>;

export const SideMenu = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = useState('');
  const [isMenuOpened, setMenuOpened] = useState(false);
  const { t } = useTranslation();
  const ps = useStores().permissionsStore;
  const ls = useStores().languagesStore;
  const profileStore = useStores().profileStore;

  useEffect(() => {
    setIsActive(location.pathname);
    setMenuOpened(false);
  }, [location.pathname]);

  return (
    <>
      <div className='menu-hamburger' onClick={() => setMenuOpened(!isMenuOpened)}>
        <Hamburger isOpen={isMenuOpened} />
      </div>
      <div className={`menu-container ${isMenuOpened ? 'menu-opened' : ''}`}>
        <div className='menu-logo'>
          <img src={logo} alt='Trackman Logo' />
        </div>
        <div className='menu-items-container'>
          <div className='top-container'>
            <MenuItem
              label={t('Home', { ns: 'homePage' })}
              onClick={() => navigate(DefaultRoutes.Home)}
              isActive={isActive.includes(DefaultRoutes.Home)}
              icon={<Home />}
            />
            <MenuItem
              label={t('TrackmanParameters', { ns: 'trackmanParameters' })}
              onClick={() => navigate(DefaultRoutes.TrackmanParameters)}
              isActive={location.pathname.startsWith(DefaultRoutes.TrackmanParameters)}
              icon={<Parameters />}
            />
            <MenuItem
              label={t('LearningMaterials', { ns: 'general' })}
              onClick={() => navigate(DefaultRoutes.LearningMaterials)}
              isActive={location.pathname.startsWith(DefaultRoutes.LearningMaterials)}
              icon={<Document />}
            />
            <MenuItem
              label={t('ModulesAndTests', { ns: 'general' })}
              onClick={() => navigate(DefaultRoutes.ModulesAndTests)}
              isActive={isActive.includes(DefaultRoutes.ModulesAndTests)}
              icon={<Trophy />}
            />
            <MenuItem
              label={t('Certifications', { ns: 'general' })}
              onClick={() => navigate(DefaultRoutes.Certifications)}
              isActive={isActive.includes(DefaultRoutes.Certifications)}
              icon={<Medal />}
            />
            {ps.userPermissions.organizations.length > 0 ? (
              <MenuItem
                label={t('Organization', { ns: 'general' })}
                onClick={() => navigate(DefaultRoutes.Organization)}
                isActive={location.pathname.startsWith(DefaultRoutes.Organization)}
                icon={<League />}
              />
            ) : (
              <></>
            )}
            {typeof ps.userPermissions.courses === 'object' ? (
              <div className='admin-menu-item--container'>
                <div
                  onClick={() => navigate(DefaultRoutes.Admin)}
                  className={`menu-item ${isActive.includes(DefaultRoutes.Admin) ? 'active' : ''}`}
                >
                  <div>
                    <Adjustment />
                  </div>
                  <div>{t('Admin', { ns: 'general' })}</div>
                </div>
                <AdminSubmenu />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className='bottom-container'>
            <MenuItem
              label={t('Portal', { ns: 'general' })}
              isActive={false}
              icon={<TwoPersons />}
              onClick={() => window.open('https://portal.trackmangolf.com/', '_blank')}
            />
            <MenuItem
              label={t('TrackmanCom', { ns: 'general' })}
              isActive={false}
              icon={<Radar />}
              onClick={() => window.open('https://www.trackman.com/', '_blank')}
            />
            <MenuItem
              label={t('UpgameByTrackman', { ns: 'general' })}
              isActive={false}
              icon={<BallTotal />}
              onClick={() => {
                if (window.screen.width >= 1024) {
                  window.open('https://www.upgame.app/', '_blank');
                } else {
                  setTimeout(function () {
                    window.open('https://apps.apple.com/in/app/upgame-golf/id1221028917', '_blank');
                  }, 25);
                  window.location.href = 'upgame-golf://';
                }
              }}
            />
            <MenuItem
              label={t('MyProfile', { ns: 'general' })}
              isActive={isActive.includes(DefaultRoutes.Profile)}
              icon={<Profile />}
              submenu={<ProfileSubmenu />}
            />
            <MenuItem
              label={t('Feedback', { ns: 'general' })}
              onClick={() => {
                _urq.push(['Feedback_Open']);
                _urq.push(['setFeedbackForumUserName', getUsername(profileStore.profileData.firstName, profileStore.profileData.lastName)]);
                _urq.push(['setFeedbackForumUserEmail', profileStore.profileData.email]);
              }}
              isActive={false}
              icon={<SpeechBubble />}
            />
            <MenuItem
              label={getLanguageText(ls.userLanguage)}
              isActive={false}
              icon={<CountryFlag countryCode={localStorage.i18nextLng} />}
              submenu={<LanguageSubmenu />}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default SideMenu;
