import '../../../../pages/Organization/Organization.scss';

import { Modal } from '@trackman/web-shared-components';
import { ReactComponent as PencilIcon } from 'images/icons/Pencil.svg';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type Props = {
  isModalOn: boolean;
  setIsModalOn: (isModalOn: boolean) => void;
};

export const RenameClassModal = observer(({ isModalOn, setIsModalOn }: Props) => {
  const { t } = useTranslation();
  const cs = useStores().classesStore;

  return (
    <Modal
      open={isModalOn}
      onClose={() => setIsModalOn(false)}
      aria-labelledby={t('RenameClass', { ns: 'classPage' })}
      aria-describedby={t('RenameSelectedClass', { ns: 'classPage' })}
      className='organization-modal'
      cancelBtn={{
        label: t('Cancel', { ns: 'general' }),
        onClick: () => setIsModalOn(false),
        className: 'styled-button secondary',
      }}
      confirmBtn={{
        label: t('Save', { ns: 'classPage' }),
        onClick: () => {
          cs.renameClassAndUpdate();
          setIsModalOn(false);
        },
      }}
      title={t('EditClassName', { ns: 'classPage' })}
      titleIcon={<PencilIcon />}
    >
      <div className='modal-body'>
        <p className='name-your-class'>
          <strong>{t('NameYourClass', { ns: 'organizationPage' })}</strong>
        </p>
        <ProfileInput
          label={t('NameYourClass', { ns: 'organizationPage' })}
          value={cs.editedClass.name ?? ''}
          onChange={(newValue) => (cs.editedClass.name = newValue)}
        />
      </div>
    </Modal>
  );
});

export default RenameClassModal;
