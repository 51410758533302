import './Organization.scss';
import '../../styles/_table.scss';

import { OrganizationTabsRelativeRoutes } from 'Routes';
import Paragraph from 'components/TextComps/Paragraph';
import { Select } from '@trackman/web-shared-components';
import Tabs from 'components/Tabs/Tabs';
import i18next from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type TabRouteKeys = keyof typeof OrganizationTabsRelativeRoutes;

const organizationTabNamesByRoutes: { [key in TabRouteKeys]: string } = {
  Progress: i18next.t('StudentsProgress', { ns: 'general' }),
  Students: i18next.t('Students', { ns: 'general' }),
  Classes: i18next.t('Classes', { ns: 'studentsPage' }),
} as const;

export const Organization = observer(() => {
  const { t } = useTranslation();
  const orgStore = useStores().organizationsStore;
  const cs = useStores().classesStore;

  useSetPageTitle({
    title: t('Organization', { ns: 'general' }),
    subtitle: t('TrackProgressAndCourseCompletion', { ns: 'organizationPage' }),
  });

  useEffect(() => {
    orgStore.orgList.length === 0 && orgStore.getOrganizations();
  }, [orgStore]);

  useEffect(() => {
    orgStore.selectedOrg.id && cs.getClasses(false);
  }, [cs, orgStore, orgStore.selectedOrg.id]);

  return (
    <>
      <div className='organization-section select-organization-section'>
        <h2>{t('Organization', { ns: 'general' })}</h2>
        <Paragraph>
          <>{t('PleaseSelectTheOrganizationYouArePlanningToAdministrate', { ns: 'organizationPage' })}</>
        </Paragraph>
        <Select
          label={t('Organization', { ns: 'general' })}
          value={orgStore.selectedOrg.id ?? ''}
          className='text-field'
          error={false}
          onChange={(event) =>
            (orgStore.selectedOrg =
              orgStore.orgList.find((organization) => organization.id === (event.target.value as string)) ?? orgStore.selectedOrg)
          }
          selectOptions={orgStore.orgList.map((organization) => {
            return {
              label: organization.name === ' ' ? t('PleaseSelectOrganization', { ns: 'organizationPage' }) : organization.name,
              value: organization.id,
            };
          })}
        />
      </div>
      <div className='organization-tabs-wrapper'>
        <Tabs tabs={organizationTabNamesByRoutes} defaultTabName={organizationTabNamesByRoutes.Progress} />
      </div>
    </>
  );
});

export default Organization;
