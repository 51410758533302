import '../../../../pages/Admin/Admin.scss';
import '../../../../styles/_table.scss';

import { Table, TableBody, TableCell, TableHead, TableRow } from '@trackman/web-shared-components';

import { AdminCourseModel } from 'types/admin/courses';
import { AdminLessonModel } from 'types/admin/lessons';
import { ReactComponent as MinusIcon } from 'images/icons/MinusInCircle.svg';
import Paragraph from 'components/TextComps/Paragraph';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useStores } from 'index';

type Props = {
  dataItem: AdminLessonModel | AdminCourseModel;
};

type RowsDisplay = {
  id: string;
  name: string;
};

export const AddedOrganizationsTable = observer(({ dataItem }: Props) => {
  const org = useStores().organizationsEditorStore;

  const rowsDisplay: RowsDisplay[] = useMemo(
    () =>
      dataItem.organizationIds
        .map((id) => {
          const organization = org.organizationsList.find((org) => org.id === id);
          if (organization === undefined) return undefined;
          return {
            id: organization.id,
            name: organization.name,
          };
        })
        .filter((row) => row !== undefined) as RowsDisplay[],
    [JSON.stringify(dataItem.organizationIds), dataItem.organizationIds, org.organizationsList]
  );

  return (
    <div className='tmu-table-wrapper admin-edit-section'>
      <div className='table-subheading'>
        <strong>Added organizations</strong>
      </div>
      <Table sx={{ minWidth: 750 }} className='tmu-table admin-table'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsDisplay.length ? (
            rowsDisplay.map((org) => {
              return (
                <TableRow hover key={org.id}>
                  <TableCell>
                    <button
                      className='row-button'
                      onClick={() =>
                        dataItem.organizationIds?.length
                          ? (dataItem.organizationIds = dataItem.organizationIds?.filter((id) => id !== org.id))
                          : {}
                      }
                    >
                      <MinusIcon />
                    </button>
                    {org.name}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell>
                <Paragraph>
                  <>No organizations have been added.</>
                </Paragraph>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
});

export default AddedOrganizationsTable;
