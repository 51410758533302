import './Select.scss';

import { Dispatch, SetStateAction, memo } from 'react';

import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import Select from '@trackman/web-shared-components/components/Select';
import { SelectVariable } from 'components/FullCalculator/constants/supportedVariablesAndParameters';
import { getValueByPath } from 'components/FullCalculator/helpers/valueManipulators';
import { isSelectHidden } from '../helpers/hiddenVariables';
import useClubType from 'components/FullCalculator/hooks/useClubType';
import { useSelectConstants } from 'components/FullCalculator/hooks/useConverterConstants';
import useSelectOptions from '../hooks/useSelectOptions';
import useValueSetter from 'components/FullCalculator/hooks/useValueSetter';

type Props = {
  calcOptions: CalculatorOptions;
  setCalcOptions: Dispatch<SetStateAction<CalculatorOptions>>;
  selectVariable: SelectVariable;
};

export const CalculactorSelect = memo(({ calcOptions, setCalcOptions, selectVariable }: Props) => {
  const { parameter, label } = useSelectConstants(selectVariable);
  const { setCalcVariable } = useValueSetter(setCalcOptions);
  const { clubType } = useClubType(calcOptions);
  const { selectOptions } = useSelectOptions({ calcOptions, parameter });

  return isSelectHidden(clubType, parameter) ? (
    <></>
  ) : (
    <div className='dropdown-container'>
      <Select
        label={label}
        value={getValueByPath({ readableObject: calcOptions, readPath: selectVariable }).toString()}
        onChange={(e) => setCalcVariable(selectVariable, e.target.value as string | number)}
        width='100%'
        selectOptions={selectOptions}
      />
    </div>
  );
});

export default CalculactorSelect;
