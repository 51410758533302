import AddedOrganizationsTable from 'components/Admin/OrganizationsTables/AddedOrganizationsTable/AddedOrganizationsTable';
import AdminEditWarning from 'components/Admin/AdminEditWarning/AdminEditWarning';
import Area9EntrypointInput from 'components/Admin/Area9EntrypointInput/Area9EntrypointInput';
import AvailableOrganizationsTable from 'components/Admin/OrganizationsTables/AvailableOrganizationsTable/AvailableOrganizationsTable';
import CertificationSetting from 'components/Admin/StatusSettings/CertificationSetting';
import ComingSoonSetting from 'components/Admin/StatusSettings/ComingSoonSetting';
import { DescriptionInputs } from 'components/Admin/DescriptionInputs/DescriptionInputs';
import JSONPreview from 'components/Admin/JSONPreview/JSONPreview';
import LanguageSettings from 'components/Admin/LanguageSettings/LanguageSettings';
import PrerequisitesTable from 'components/Admin/PrerequisitesTable/PrerequisitesTable';
import TitleInputs from 'components/Admin/TitleInputs/TitleInputs';
import VisibleSetting from 'components/Admin/StatusSettings/VisibleSetting';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

export const EditLesson = observer(() => {
  useSetPageTitle({ title: 'Edit lesson', subtitle: '' });
  const { lessonId } = useParams();
  const ls = useStores().lessonEditorStore;
  const org = useStores().organizationsEditorStore;

  useEffect(() => {
    ls.getLessons();
  }, [ls]);

  useEffect(() => {
    if (lessonId) {
      ls.getLesson(lessonId);
    }
    return () => {
      ls.lesson = { ...ls.cleanLesson };
    };
  }, [ls, lessonId]);

  useEffect(() => {
    org.getOrganizations();
  }, [org]);

  const handleSaveLesson = () => {
    if (lessonId) {
      ls.saveEditedLessonAndUpdate(lessonId);
    } else {
      ls.saveNewLessonAndUpdate();
    }
  };

  return (
    <>
      <div className='admin-content-container'>
        <AdminEditWarning />
        <LanguageSettings dataItem={ls.lesson} />
        <TitleInputs dataItem={ls.lesson} />
        <DescriptionInputs dataItem={ls.lesson} />
        <Area9EntrypointInput />
        <div className='admin-edit-section'>
          <h2>Settings</h2>
          <div className='checkboxes-container'>
            <VisibleSetting dataItem={ls.lesson} />
            <ComingSoonSetting dataItem={ls.lesson} />
            <CertificationSetting dataItem={ls.lesson} />
          </div>
        </div>
        <PrerequisitesTable tableTitle='Lesson' noDataText='No lessons found.' dataList={ls.lessonsList} dataItem={ls.lesson} />
        <AddedOrganizationsTable dataItem={ls.lesson} />
        <AvailableOrganizationsTable dataItem={ls.lesson} />
        <JSONPreview jsonPreview={JSON.stringify(ls.lesson, null, 2)} />
        <div className='admin-footer'>
          <button
            className='styled-button primary'
            onClick={() => handleSaveLesson()}
            disabled={ls.lesson.titles.find((title) => title.countryCode === 'en-US')?.text === ''}
          >
            Save changes
          </button>
        </div>
      </div>
    </>
  );
});

export default EditLesson;
