import '../../../../pages/Organization/Organization.scss';
import './AddStudentsToClassModal.scss';

import { Checkbox, Modal, Table, TableCell, TableHead, TableRow } from '@trackman/web-shared-components';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ReactComponent as AddStudentsIcon } from 'images/icons/AddUsers.svg';
import { ClassesTableRows } from 'types/organization/organization';
import { Invites } from 'types/organization/invites';
import InvitesModalTable from './InvitesModalTable/InvitesModalTable';
import NoStudentsFound from 'components/Organization/NoStudentsFound/NoStudentsFound';
import Paragraph from 'components/TextComps/Paragraph';
import SearchForStudents from 'components/Organization/OrganizationSearchbar/SearchForStudents/SearchForStudents';
import { Students } from 'types/organization/students';
import StudentsModalTable from './StudentsModalTable/StudentsModalTable';
import { filterUsersByClass } from 'utilities/organizationDataFilter';
import { handleSearchStudent } from 'components/Organization/OrganizationSearchbar/SearchForStudents/search-helper';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type Props = {
  isModalOn: boolean;
  setIsModalOn: (isModalOn: boolean) => void;
  selectedClassId: string;
};

export const AddStudentsToClassModal = observer(({ isModalOn, setIsModalOn, selectedClassId }: Props) => {
  const { t } = useTranslation();
  const cs = useStores().classesStore;
  const ss = useStores().studentsStore;
  const iss = useStores().inviteStudentsStore;
  const [studentsToAdd, setStudentsToAddd] = useState(ss.students);
  const [invitesToAdd, setInvitesToAdd] = useState(iss.invites);

  useEffect(() => {
    setStudentsToAddd(filterUsersByClass(ss.students, selectedClassId, false) as Students);
    setInvitesToAdd(filterUsersByClass(iss.invites, selectedClassId, false) as Invites);
  }, [selectedClassId, ss.students, iss.invites]);

  useEffect(() => {
    if (cs.searchInput !== '') {
      setStudentsToAddd(handleSearchStudent(cs.searchInput, studentsToAdd));
      setInvitesToAdd(handleSearchStudent(cs.searchInput, invitesToAdd));
    } else {
      setStudentsToAddd(filterUsersByClass(ss.students, selectedClassId, false) as Students);
      setInvitesToAdd(filterUsersByClass(iss.invites, selectedClassId, false) as Invites);
    }
  }, [ss, cs.searchInput, iss, selectedClassId]);

  const initials = useCallback((name: string | null) => {
    return name
      ? name
          .split(' ')
          .map((a) => a[0])
          .join('')
      : 'N/A';
  }, []);

  const studentsRows = useMemo((): ClassesTableRows[] => {
    let dynamicRows: ClassesTableRows[] = [];

    studentsToAdd.map((student) => {
      dynamicRows.push({
        id: student.id,
        initials: initials(student.name),
        name: student.name,
        classes: student.classes ? student.classes.map((classItem) => classItem.name).join(', ') : '',
        email: student.email,
      });

      return dynamicRows;
    });

    return dynamicRows;
  }, [studentsToAdd, initials]);

  const invitesRows = useMemo((): ClassesTableRows[] => {
    let dynamicRows: ClassesTableRows[] = [];

    invitesToAdd.map((invite) => {
      dynamicRows.push({
        id: invite.id,
        initials: initials(invite.name),
        name: invite.name || '',
        classes: invite.classes ? invite.classes.map((classItem) => classItem.name).join(', ') : '',
        email: invite.email,
      });
      return dynamicRows;
    });

    return dynamicRows;
  }, [invitesToAdd, initials]);

  const rows = useMemo(() => [...studentsRows, ...invitesRows], [studentsRows, invitesRows]);

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        cs.studentsToAdd = studentsRows.map((n) => n.id);
        cs.invitesToAdd = invitesRows.map((n) => n.id);
        return;
      }
      cs.studentsToAdd = [];
      cs.invitesToAdd = [];
    },
    [cs, studentsRows, invitesRows]
  );

  const onModalClose = () => {
    setIsModalOn(false);
    cs.studentsToAdd = [];
    cs.invitesToAdd = [];
  };

  return (
    <Modal
      open={isModalOn}
      confirmBtn={{
        label: t('AddStudents', { ns: 'studentsPage' }),
        onClick: () => {
          cs.addSelectedStudentsToClass();
          onModalClose();
        },
        disabled: cs.studentsToAdd.length === 0 && cs.invitesToAdd.length === 0,
      }}
      cancelBtn={{
        label: t('Cancel', { ns: 'general' }),
        onClick: () => onModalClose(),
        className: 'styled-button secondary',
      }}
      onClose={() => onModalClose()}
      className='add-students-to-class-modal organization-modal'
      title={t('AddStudents', { ns: 'studentsPage' })}
      titleIcon={<AddStudentsIcon />}
    >
      <div className='modal-body'>
        <div className='modal-subtitle'>
          <Paragraph>
            <strong>{t('SelectWhichStudentsShouldBeAddedToThisClass', { ns: 'studentsPage' })}</strong>
          </Paragraph>
          <Paragraph>
            <>{t('PickByBrowsingTheTableOrSearch', { ns: 'studentsPage' })}</>
          </Paragraph>
        </div>
        {!ss.students.length && !iss.invites.length ? (
          <NoStudentsFound />
        ) : (
          <>
            <SearchForStudents label={''} input={cs.searchInput} onChange={(newValue: string) => (cs.searchInput = newValue)} />
            <div className='modal-table-container'>
              <Table sx={{ minWidth: 750 }} aria-labelledby='studentsTable'>
                <TableHead>
                  <TableRow>
                    <TableCell padding='checkbox'>
                      <Checkbox
                        color='primary'
                        indeterminate={
                          cs.studentsToAdd.length > 0 ||
                          (cs.invitesToAdd.length > 0 && cs.studentsToAdd.length + cs.invitesToAdd.length < rows.length)
                        }
                        checked={rows.length > 0 && rows.length === cs.studentsToAdd.length + cs.invitesToAdd.length}
                        onChange={(e) => handleSelectAllClick(e)}
                        label={undefined}
                      />
                    </TableCell>
                    <TableCell sx={{ width: 100 }}></TableCell>
                    <TableCell sx={{ width: 250 }}>{t('Name', { ns: 'studentsPage' })}</TableCell>
                    <TableCell sx={{ width: 250 }}>{t('Classes', { ns: 'studentsPage' })}</TableCell>
                    <TableCell sx={{ width: 250 }}>{t('Email', { ns: 'studentsPage' })}</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              {studentsToAdd.length ? <StudentsModalTable rows={studentsRows} /> : <></>}
              {invitesToAdd.length ? <InvitesModalTable rows={invitesRows} /> : <></>}
            </div>
          </>
        )}
      </div>
      <div className='modal-summary'>
        <Paragraph>
          <strong>
            {t('NumberOfStudentsSelected', { ns: 'studentsPage' })} {cs.studentsToAdd.length + cs.invitesToAdd.length}
          </strong>
        </Paragraph>
      </div>
    </Modal>
  );
});

export default AddStudentsToClassModal;
