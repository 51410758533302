import '../../../../pages/Admin/Admin.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { useCallback, useEffect } from 'react';

import ImageUploader from 'components/Admin/ImageUploader/ImageUploader';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { TextArea } from '@trackman/web-shared-components';
import { cleanPartner } from 'stores/Admin/Editors/PartnersEditorStore';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

export const EditPartner = observer(() => {
  useSetPageTitle({ title: 'Edit Partner', subtitle: '' });
  const partnerId = useParams().partnerId;
  const pes = useStores().partnersEditorStore;

  useEffect(() => {
    if (partnerId) {
      pes.getPartner(partnerId);
    }
    return () => {
      pes.partner = structuredClone(cleanPartner);
    };
  }, [pes, partnerId]);

  const handleUploadImage = useCallback(
    (data: File) => {
      pes.partner.file = {
        id: '',
        data: data,
      };
    },
    [pes.partner]
  );

  const savePartner = useCallback(() => {
    if (partnerId) {
      pes.saveExistingPartnerAndUpdate();
    } else {
      pes.saveNewPartnerAndUpdate();
    }
  }, [partnerId, pes]);

  return (
    <div className='admin-content-container'>
      <div className='admin-edit-section'>
        <h2>Partner's name</h2>
        <div className='inputs-container'>
          <div className='input-wrapper'>
            <ProfileInput
              label={`Full name`}
              value={pes.partner.fullName ?? ''}
              onChange={(newValue) => (pes.partner.fullName = newValue)}
            />
          </div>
        </div>
      </div>

      <div className='admin-edit-section'>
        <h2>Description</h2>
        <TextArea value={pes.partner.description} onChange={(e) => (pes.partner.description = e.currentTarget.value)} />
      </div>

      <div className='admin-edit-section'>
        <h2>Partner's image</h2>
        <ImageUploader imageData={pes.partner.file} imageUrl={pes.partner.imageUrl} handleUploadImage={handleUploadImage} />
      </div>
      <div className='admin-footer'>
        <button
          className='styled-button primary'
          onClick={() => savePartner()}
          disabled={pes.partner.fullName === '' || (!pes.partner.imageUrl && pes.partner.file === undefined)}
        >
          Save changes
        </button>
      </div>
    </div>
  );
});

export default EditPartner;
