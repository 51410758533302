import { Modal, Select } from '@trackman/web-shared-components';
import { useCallback, useEffect, useMemo } from 'react';

import { BillingInfoUpdate } from 'types/billing';
import { ReactComponent as PencilIcon } from 'images/icons/Pencil.svg';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { observer } from 'mobx-react-lite';
import { trimTooManySpaces } from '../../../utilities/inputValidation';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type BillingAddressProps = {
  isModalOn: boolean;
  setIsModalOn: (arg0: boolean) => void;
};

export const BillingAddress = observer(({ isModalOn, setIsModalOn }: BillingAddressProps) => {
  const ps = useStores().profileStore;
  const bs = useStores().billingStore;
  const { t } = useTranslation();

  const isFormValid = useMemo(
    () =>
      !!bs.billingInfoUpdate.Company &&
      !!bs.billingInfoUpdate.FirstName &&
      !!bs.billingInfoUpdate.LastName &&
      !!bs.billingInfoUpdate.Address1 &&
      !!bs.billingInfoUpdate.PostalCode &&
      !!bs.billingInfoUpdate.City &&
      !!bs.billingInfoUpdate.Country &&
      (bs.billingInfoUpdate.Country === 'US' ? !!bs.billingInfoUpdate.State : true),

    [
      bs.billingInfoUpdate.Company,
      bs.billingInfoUpdate.FirstName,
      bs.billingInfoUpdate.LastName,
      bs.billingInfoUpdate.Address1,
      bs.billingInfoUpdate.PostalCode,
      bs.billingInfoUpdate.City,
      bs.billingInfoUpdate.Country,
      bs.billingInfoUpdate.State,
    ]
  );

  const inputChangeHandler = useCallback(
    (newValue: string, key: keyof BillingInfoUpdate) => {
      bs.billingInfoUpdate[key] = trimTooManySpaces(newValue);
      if (!newValue.replace(/\s/g, '').length) bs.billingInfoUpdate[key] = '';
    },
    [bs.billingInfoUpdate]
  );

  useEffect(() => {
    ps.getSignUpLists();
  }, [ps]);

  return (
    <Modal
      open={isModalOn}
      onClose={() => setIsModalOn(false)}
      title={t('EditBillingInformation', { ns: 'billingInformation' })}
      titleIcon={<PencilIcon />}
      className='edit-payment-method-modal'
      cancelBtn={{
        label: t('Cancel', { ns: 'general' }),
        onClick: () => setIsModalOn(false),
        className: 'styled-button secondary',
      }}
      confirmBtn={{
        label: t('SaveChanges', { ns: 'editProfile' }),
        onClick: () => {
          bs.updateBillingAddress();
          setIsModalOn(false);
        },
        disabled: bs.isBillingInfoUpdating || !isFormValid,
      }}
    >
      <div className='modal-body'>
        <div className='profile-inputs-column'>
          <h3 className='section-subtitle'>{t('BillingDetails', { ns: 'billingInformation' })}</h3>
          <ProfileInput
            label={t('CompanyWithAsterisk', { ns: 'billingInformation' })}
            value={bs.billingInfoUpdate.Company ?? ''}
            type='text'
            onChange={(newValue) => {
              inputChangeHandler(newValue, 'Company');
            }}
          />
          <ProfileInput
            label={t('FirstNameWithAsterisk', { ns: 'billingInformation' })}
            value={bs.billingInfoUpdate.FirstName ?? ''}
            type='text'
            onChange={(newValue) => {
              inputChangeHandler(newValue, 'FirstName');
            }}
          />
          <ProfileInput
            label={t('LastNameWithAsterisk', { ns: 'billingInformation' })}
            value={bs.billingInfoUpdate.LastName ?? ''}
            type='text'
            onChange={(newValue) => {
              inputChangeHandler(newValue, 'LastName');
            }}
          />
          <ProfileInput
            label={t('StreetName1WithAsterisk', { ns: 'billingInformation' })}
            value={bs.billingInfoUpdate.Address1 ?? ''}
            type='text'
            onChange={(newValue) => {
              inputChangeHandler(newValue, 'Address1');
            }}
          />
          <ProfileInput
            label={t('StreetName2', { ns: 'editProfile' })}
            value={bs.billingInfoUpdate.Address2 ?? ''}
            type='text'
            onChange={(newValue) => (bs.billingInfoUpdate.Address2 = newValue)}
          />
          <ProfileInput
            label={t('PostalCodeWithAsterisk', { ns: 'billingInformation' })}
            value={bs.billingInfoUpdate.PostalCode ?? ''}
            type='text'
            onChange={(newValue) => {
              inputChangeHandler(newValue, 'PostalCode');
            }}
          />
          <ProfileInput
            label={t('CityWithAsterisk', { ns: 'billingInformation' })}
            value={bs.billingInfoUpdate.City ?? ''}
            type='text'
            onChange={(newValue) => {
              inputChangeHandler(newValue, 'City');
            }}
          />
          <Select
            label={t('CountryWithAsterisk', { ns: 'billingInformation' })}
            value={bs.billingInfoUpdate.Country ?? ''}
            className='text-field'
            onChange={(event) => inputChangeHandler(event.target.value as string, 'Country')}
            selectOptions={ps.signUpLists.Countries.map((country) => {
              return {
                label: country.name === ' ' ? t('PleaseSelectACountry', { ns: 'editProfile' }) : country.name,
                value: country.twoLetterIsoCode,
              };
            })}
          />
          {bs.billingInfoUpdate.Country === 'US' && (
            <Select
              label={t('StateWithAsterisk', { ns: 'billingInformation' })}
              value={bs.billingInfoUpdate.State ?? ' ' ?? ''}
              className='text-field'
              onChange={(event) => inputChangeHandler(event.target.value as string, 'State')}
              selectOptions={ps.signUpLists.States.map((state) => {
                return {
                  label: state === ' ' ? t('PleaseSelectAState', { ns: 'editProfile' }) : state,
                  value: state,
                };
              })}
            />
          )}
        </div>
      </div>
    </Modal>
  );
});

export default BillingAddress;
