import { AdminCourseModel, AdminCoursesList } from 'types/admin/courses';
import { AdminLessonModel, AdminLessonsList } from 'types/admin/lessons';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@trackman/web-shared-components';
import { useMemo, useState } from 'react';

import { ReactComponent as MinusIcon } from 'images/icons/MinusInCircle.svg';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as PlusIcon } from 'images/icons/Plus.svg';
import PrerequisitesModal from '../Modals/PrerequisitesModal/PrerequisitesModal';
import { observer } from 'mobx-react-lite';

type Props = {
  tableTitle: string;
  noDataText: string;
  dataList: AdminLessonsList | AdminCoursesList;
  dataItem: AdminLessonModel | AdminCourseModel;
};

type RowsDisplay = {
  id: string;
  title: string;
};

export const PrerequisitesTable = observer(({ tableTitle, noDataText, dataList, dataItem }: Props) => {
  const [isModalOn, setIsModalOn] = useState(false);

  const rowsDisplay: RowsDisplay[] = useMemo(
    () =>
      dataItem.prerequisiteIds.map((id) => {
        let prerequisite: AdminCourseModel | AdminLessonModel | undefined;
        if (dataList[0] && 'lessonIds' in dataList[0]) {
          prerequisite = (dataList as AdminCoursesList).find((course) => course.id === id);
        } else if (dataList[0] && 'enterpoint' in dataList[0]) {
          prerequisite = (dataList as AdminLessonsList).find((lesson) => lesson.id === id);
        }
        if (prerequisite === undefined) return undefined;
        return {
          id: prerequisite.id,
          title: prerequisite.titles.find((title) => title.countryCode === 'en-US')?.text,
        };
      }) as RowsDisplay[],
    [dataList, dataItem.prerequisiteIds, JSON.stringify(dataItem.prerequisiteIds), JSON.stringify(dataList)]
  );

  return (
    <div className='tmu-table-wrapper admin-edit-section'>
      <div className='table-subheading'>
        <strong className='prerequisites-title'>{tableTitle} prerequisites</strong>
        <button onClick={() => setIsModalOn(true)} className='add-button styled-button primary'>
          <PlusIcon />
          <span>Choose {tableTitle}</span>
        </button>
      </div>
      <Table sx={{ minWidth: 750 }} className='tmu-table admin-table'>
        <TableHead>
          <TableRow>
            <TableCell>Prerequisites {tableTitle} names</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsDisplay.length && rowsDisplay[0] !== undefined ? (
            rowsDisplay.map((row) => {
              return (
                <TableRow hover key={row.id} sx={{ cursor: 'pointer' }}>
                  <TableCell>
                    <button
                      className='row-button'
                      onClick={() =>
                        dataItem.prerequisiteIds?.length
                          ? (dataItem.prerequisiteIds = dataItem.prerequisiteIds?.filter((id) => id !== row.id))
                          : {}
                      }
                    >
                      <MinusIcon />
                    </button>
                    {row.title.toLowerCase()}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={3}>
                <Paragraph>
                  <>No prerequisites added</>
                </Paragraph>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <PrerequisitesModal
        isModalOn={isModalOn}
        setIsModalOn={setIsModalOn}
        modalTitle={tableTitle}
        noDataText={noDataText}
        dataList={dataList}
        dataItem={dataItem}
      />
    </div>
  );
});

export default PrerequisitesTable;
