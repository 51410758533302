import './LearningMaterialsLessonPicture.scss';

import { Modal } from '@trackman/web-shared-components';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

type LearningMaterialsLessonPictureProps = {
  imageUrl: string;
  imageTitle: string;
};

export const LearningMaterialsLessonPicture = observer(({ imageUrl, imageTitle }: LearningMaterialsLessonPictureProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className='image-content lesson-content'>
        <img src={imageUrl} alt={imageTitle} onClick={handleOpen}></img>
      </div>
      <Modal open={open} onClose={handleClose} className='learning-material-image-modal'>
        <div className='modal-body'>
          <img src={imageUrl} alt={imageTitle}></img>
        </div>
      </Modal>
    </>
  );
});

export default LearningMaterialsLessonPicture;
