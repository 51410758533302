import '../PartnersTab/PartnersTab.scss';

import MasterCard, { MasterCardProps } from 'components/MasterCard/MasterCard';
import { useEffect, useState } from 'react';

import { Modal } from '@trackman/web-shared-components';
import Paragraph from 'components/TextComps/Paragraph';
import Skeleton from '@trackman/web-shared-components/components/LoadingPlaceholder/Skeleton';
import { Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const MastersTab = observer(() => {
  const { t } = useTranslation();
  const ms = useStores().mastersStore;
  const [isModalOn, setIsModalOn] = useState(false);
  const [modalData, setModalData] = useState({ name: '', description: '', url: '' });

  useEffect(() => {
    typeof ms.masters[0] === 'number' && ms.getMasters();
  }, [ms]);

  return (
    <>
      <div className='gallery-title'>
        <h2>{t('MasterGalleryTitle', { ns: 'mastersGallery' })}</h2>
        {typeof ms.masters[0] !== 'number' ? (
          <Paragraph>
            <Trans
              i18nKey='LearnMoreAboutMasters'
              ns='mastersGallery'
              defaults='Learn more about the <bold>{{mastersCount}} people</bold>  who have received the Trackman Masters status'
              values={{ mastersCount: ms.masters.length }}
              components={{ bold: <strong /> }}
            />
          </Paragraph>
        ) : (
          <Skeleton variant='text' width={200} sx={{ height: '18px' }} />
        )}
      </div>
      <div className='gallery-container'>
        {ms.masters.map((master, i) => {
          const props: MasterCardProps = master?.id
            ? {
                profileUrl: master.imageUrl,
                name: master.fullName,
                description: master.description,
                skeleton: false,
                handleSetIsModalOpen: setIsModalOn,
                handleSetModalData: setModalData,
              }
            : { skeleton: true };
          return <MasterCard key={`master-${i}`} {...props} delay={i * 50} />;
        })}

        <Modal open={isModalOn} onClose={() => setIsModalOn(false)} className='master-card-modal'>
          <div className='master-card'>
            <div className='left-col'>
              <img className='master-card-img' src={modalData.url} alt={`${modalData.name}`} />
              <div className='master-card-name'>{modalData.name}</div>
            </div>
            <div className='right-col'>
              <div className='master-card-description'>{modalData.description}</div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
});

export default MastersTab;
