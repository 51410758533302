import '../ErrorPages.scss';

import { Button } from '@trackman/web-shared-components';
import PageNotFound from 'images/PageNotFound404.svg';
import Paragraph from 'components/TextComps/Paragraph';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const NotFound = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className='error-page'>
      <img src={PageNotFound} alt={t('PageNotFound', { ns: 'general' })} />
      <h2>{t('PageNotFound', { ns: 'general' })}</h2>
      <Paragraph>
        <>{t('LooksLikeThePageYouAreTryingToReachDoesNotExist', { ns: 'general' })}</>
      </Paragraph>
      <Button
        className='styled-button primary'
        onClick={() => {
          navigate('/');
        }}
      >
        {t('HomePage', { ns: 'general' })}
      </Button>
    </div>
  );
});

export default NotFound;
