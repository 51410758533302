import './TranslatorPage.scss';
import '../Admin.scss';

import { Loader, Select } from '@trackman/web-shared-components';
import { TranslationMaterial, TranslationTask } from 'types/admin/translations';

import AdminEditWarning from 'components/Admin/AdminEditWarning/AdminEditWarning';
import { ReactComponent as CheckmarkIcon } from 'images/icons/CheckmarkInCircle.svg';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useState } from 'react';
import { useStores } from 'index';

const tasks = [
  { label: 'Add new terms in POEditor', value: 'sync' },
  { label: 'Update terms in POEditor', value: 'update' },
  { label: 'Import translations from POEditor', value: 'import' },
];

const materials = [
  { label: 'Course', value: 'course' },
  { label: 'Lesson', value: 'lesson' },
  { label: 'Learning Material', value: 'learningmaterial' },
  { label: 'Library', value: 'Library' },
  { label: 'Library Lesson', value: 'librarylesson' },
  { label: 'Question', value: 'question' },
  { label: 'Trackman Parameters', value: 'trackman-parameters' },
];

export const TranslatorPage = observer(() => {
  useSetPageTitle({ title: 'Translator', subtitle: '' });
  const ls = useStores().languagesStore;
  const ts = useStores().translationsStore;
  const [step, setStep] = useState<number>(1);
  const [doneSteps, setDoneSteps] = useState<number[]>([]);

  const handleNext = (currentStep: number) => {
    handleSetStep(currentStep + 1);

    if (!doneSteps.includes(currentStep)) {
      setDoneSteps([...doneSteps, currentStep]);
    }
  };

  const handlePrev = (currentStep: number) => {
    handleSetStep(currentStep - 1);
    setDoneSteps(doneSteps.filter((step) => step !== currentStep));
  };

  const handleSetStep = (newStep: number) => {
    if (ts.translationsTask !== 'import' && newStep === 3) {
      setStep(4);
    } else {
      setStep(newStep);
    }
  };

  const clearForm = () => {
    ts.translationsTask = '';
    ts.translationsMaterial = '';
    ts.importLng = '';
    setStep(1);
    setDoneSteps([]);
  };

  const handleConfirm = () => {
    switch (ts.translationsTask) {
      case 'sync':
        ts.syncTranslations();
        break;
      case 'update':
        ts.updateTranslations();
        break;
      case 'import':
        ts.importTranslations();
        break;
      default:
        break;
    }
    clearForm();
  };

  return (
    <div className='admin-content-container translator-container'>
      <AdminEditWarning />
      <div className='translations-step-wrapper'>
        {ts.isLoading ? (
          <div className='loader-wrapper'>
            <Loader size='large' />
          </div>
        ) : (
          <></>
        )}
        <div className={`admin-edit-section ${step !== 1 || ts.isLoading ? 'inactive' : ''}`}>
          <h2>Step 1 - select task</h2>
          <div className='inputs-container'>
            <Select
              label='Task'
              className='text-field'
              onChange={(event) => (ts.translationsTask = event.target.value as TranslationTask)}
              selectOptions={tasks}
              value={ts.translationsTask ?? ''}
            />
            <button
              className='styled-button secondary'
              onClick={() => handleNext(step)}
              disabled={step !== 1 || ts.translationsTask === ''}
            >
              Next
            </button>
          </div>
          {step !== 1 ? (
            doneSteps.includes(1) ? (
              <div className='checkmark-icon'>
                <CheckmarkIcon />
              </div>
            ) : (
              <div className='step-number'>
                <span>1</span>
              </div>
            )
          ) : (
            <></>
          )}
        </div>
        <div className={`admin-edit-section ${step !== 2 ? 'inactive' : ''}`}>
          <h2>Step 2 - select material</h2>
          <div className='inputs-container'>
            <Select
              label='Material'
              className='text-field'
              value={ts.translationsMaterial ?? ''}
              onChange={(event) => (ts.translationsMaterial = event.target.value as TranslationMaterial)}
              selectOptions={materials}
            />
            <button className='styled-button tertiary' onClick={() => handlePrev(step)} disabled={step !== 2}>
              Prev
            </button>
            <button
              className='styled-button secondary'
              onClick={() => handleNext(step)}
              disabled={step !== 2 || ts.translationsMaterial === ''}
            >
              Next
            </button>
          </div>
          {step !== 2 ? (
            doneSteps.includes(2) ? (
              <div className='checkmark-icon'>
                <CheckmarkIcon />
              </div>
            ) : (
              <div className='step-number'>
                <span>2</span>
              </div>
            )
          ) : (
            <></>
          )}
        </div>
        <div className={`admin-edit-section ${step !== 3 ? 'inactive' : ''}`}>
          <h2>Step 3 - select language</h2>
          <div className='inputs-container'>
            <Select
              label='Language'
              className='text-field'
              value={ts.importLng ?? ''}
              onChange={(event) => (ts.importLng = event.target.value as string)}
              selectOptions={ls.languageInfo
                .filter((li) => li.countryCode !== 'en-US')
                .map((li) => {
                  return {
                    label: li.displayName,
                    value: li.countryCode,
                  };
                })}
            />
            <button className='styled-button tertiary' onClick={() => handlePrev(step)} disabled={step !== 3}>
              Prev
            </button>
            <button className='styled-button secondary' onClick={() => handleNext(step)} disabled={step !== 3 || ts.importLng === ''}>
              Next
            </button>
          </div>
          {step !== 3 ? (
            doneSteps.includes(3) ? (
              <div className='checkmark-icon'>
                <CheckmarkIcon />
              </div>
            ) : (
              <div className='step-number'>
                <span>3</span>
              </div>
            )
          ) : (
            <></>
          )}
        </div>
        <div className={`admin-edit-section ${step !== 4 ? 'inactive' : ''}`}>
          <h2>Step 4 - confirm</h2>
          <div className='inputs-container'>
            <button className='styled-button tertiary' onClick={() => clearForm()} disabled={step !== 4}>
              Clear
            </button>
            <button className='styled-button primary' onClick={() => handleConfirm()} disabled={step !== 4}>
              Confirm
            </button>
          </div>
          {step !== 4 ? (
            <div className='step-number'>
              <span>4</span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
});

export default TranslatorPage;
