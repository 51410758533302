import './MenuItem.scss';

import { useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react-lite';

type MenuItemProps = {
  label: string;
  icon: React.ReactNode;
  isActive: boolean;
  isSubmenu?: boolean;
} & (
  | {
      onClick: () => void;
      submenu?: undefined;
    }
  | {
      onClick?: undefined;
      submenu: JSX.Element;
    }
);

const MenuItem = observer(({ label, icon, onClick, isActive, submenu, isSubmenu = false }: MenuItemProps) => {
  const [isSubmenuOpen, setSubmenuOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setSubmenuOpen(false);
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, []);

  return (
    <div className='menu-item--container'>
      <div
        className={`menu-item ${isActive ? 'active' : ''} ${isSubmenuOpen ? 'hovered' : ''} ${isSubmenu ? 'submenu-item' : ''}`}
        onClick={() => (submenu ? setSubmenuOpen(true) : onClick())}
        ref={ref}
      >
        <div>{icon}</div>
        <div>{label}</div>
      </div>
      {submenu && <div className={`menu-item--submenu ${isSubmenuOpen ? 'is-open' : ''}`}>{submenu}</div>}
    </div>
  );
});

export default MenuItem;
