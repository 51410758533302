import { useEffect, useState } from 'react';

import { Modal } from '@trackman/web-shared-components';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type InvitationSuccess = 'True' | 'False';

type ExpectedErrorCode =
  | 'InvitationNotFound'
  | 'OrganizationNotFound'
  | 'AlreadyAccepted'
  | 'MembershipExpired'
  | 'StudentIsInOrganization';

export const InvitationModal = observer(() => {
  const [isModalOn, setIsModalOn] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalContent, setModalContent] = useState<string>('');
  let [searchParams, setSearchParams] = useSearchParams();
  const inviteError = searchParams.get('error') as ExpectedErrorCode;
  const { t } = useTranslation();

  useEffect(() => {
    const isInviteSuccessful = searchParams.get('invitesuccessfull') as InvitationSuccess;
    const inviteOrgName = searchParams.get('orgname');

    if (isInviteSuccessful === 'True' || isInviteSuccessful === 'False') {
      setIsModalOn(true);
      setSearchParams('');
    }

    if (isInviteSuccessful === 'True') {
      setModalTitle(t('AcceptInvitationSuccess', { ns: 'organizationPage' }));
      inviteOrgName &&
        setModalContent(t('YouHaveSuccessfullyJoinedTheOrganization', { ns: 'organizationPage', inviteOrgName: decodeURI(inviteOrgName) }));
    }

    if (isInviteSuccessful === 'False') {
      setModalTitle(t('AcceptInvitationError', { ns: 'organizationPage' }));
      setModalContent(t(inviteError, { ns: 'organizationPage' }));
    }
  }, [searchParams, setSearchParams, inviteError, t]);

  return (
    <Modal title={modalTitle} open={isModalOn} onClose={() => setIsModalOn(false)} className='invitation-modal'>
      <div className='modal-body'>{modalContent}</div>
    </Modal>
  );
});

export default InvitationModal;
