import '../../../../pages/Organization/Organization.scss';

import { Modal } from '@trackman/web-shared-components';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as TrashIcon } from 'images/icons/Trash.svg';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type Props = {
  isModalOn: boolean;
  setIsModalOn: (isModalOn: boolean) => void;
  selected: string[];
};

export const DeleteClassesModal = observer(({ isModalOn, setIsModalOn, selected }: Props) => {
  const { t } = useTranslation();
  const cs = useStores().classesStore;

  const deleteClasses = useCallback(() => {
    selected.map((id) => {
      cs.deleteClassAndUpdate(id);
      setIsModalOn(false);
      return true;
    });
  }, [selected, cs, setIsModalOn]);

  return (
    <Modal
      open={isModalOn}
      onClose={() => setIsModalOn(false)}
      cancelBtn={{
        label: t('Cancel', { ns: 'general' }),
        onClick: () => setIsModalOn(false),
        className: 'styled-button secondary',
      }}
      confirmBtn={{
        label: t('Delete', { ns: 'studentsPage' }),
        onClick: () => deleteClasses(),
      }}
      title={t('DeleteClass', { ns: 'classPage' })}
      titleIcon={<TrashIcon />}
      aria-labelledby={t('DeleteClass', { ns: 'classPage' })}
      aria-describedby={t('DeleteClassFromTheList', { ns: 'classPage' })}
    >
      <div className='modal-body'>
        <Paragraph>
          <>{t('AreYouSureYouWantPermanentlyDeleteThisClass', { ns: 'classPage' })}</>
        </Paragraph>
      </div>
    </Modal>
  );
});

export default DeleteClassesModal;
