import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@trackman/web-shared-components';
import { useCallback, useMemo, useState } from 'react';

import AddNewClassButton from '../AddNewClassButton/AddNewClassButton';
import AddNewClassModal from '../Modals/AddNewClassModal/AddNewClassModal';
import { ReactComponent as AddStudentsIcon } from 'images/icons/AddUsers.svg';
import AddStudentsToClassModal from '../Modals/AddStudentsToClassModal/AddStudentsToClassModal';
import DeleteClassesModal from '../Modals/DeleteClassesModal/DeleteClassesModal';
import { ReactComponent as PencilIcon } from 'images/icons/Pencil.svg';
import RenameClassModal from '../Modals/RenameClassModal/RenameClassModal';
import { ReactComponent as TrashIcon } from 'images/icons/Trash.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type DynamicRows = {
  id: string;
  name: string;
  numberOfStudents: number;
};

export const ClassesList = observer(() => {
  const { t } = useTranslation();
  const cs = useStores().classesStore;
  const ss = useStores().studentsStore;

  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [selectedClassId, setSelectedClassId] = useState<string>('');
  const [isAddStudentsModalOn, setIsAddStudentsModalOn] = useState(false);
  const [isEditClassModalOn, setEditClassModalOn] = useState(false);
  const [isDeleteClassesModalOn, setIsDeleteClassesModalOn] = useState(false);

  const rows = useMemo((): DynamicRows[] => {
    let dynamicRows: DynamicRows[] = [];

    cs.classesList.forEach((classItem) => {
      dynamicRows.push({
        id: classItem.id,
        name: classItem.name,
        numberOfStudents: ss.numberofStudents,
      });

      return dynamicRows;
    });

    return dynamicRows;
  }, [cs.classesList, ss.numberofStudents]);

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        const newSelectedRowIds = rows.map((n) => n.id);
        setSelectedRowIds(newSelectedRowIds);
        return;
      }
      setSelectedRowIds([]);
    },
    [rows]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<unknown>, id: string) => {
      const selectedIndex = selectedRowIds.indexOf(id);
      let newSelectedRowIds: string[] = [];

      if (selectedIndex === -1) {
        newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds, id);
      } else if (selectedIndex === 0) {
        newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds.slice(1));
      } else if (selectedIndex === selectedRowIds.length - 1) {
        newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds.slice(0, selectedIndex), selectedRowIds.slice(selectedIndex + 1));
      }

      setSelectedRowIds(newSelectedRowIds);
      cs.selectedClassId = id;
    },
    [selectedRowIds, cs]
  );

  const isSelected = useCallback(
    (id: string) => {
      return selectedRowIds.indexOf(id) !== -1;
    },
    [selectedRowIds]
  );

  return (
    <div className='classes-table org-table'>
      <AddNewClassButton />
      <AddNewClassModal />
      <div className='tmu-table-wrapper'>
        <div className='table-subheading'>
          <strong>
            {t('Classes', { ns: 'studentsPage' })} ({cs.classesList.length})
          </strong>
          <button className='styled-button secondary' disabled={selectedRowIds.length < 1} onClick={() => setIsDeleteClassesModalOn(true)}>
            <TrashIcon />
            <span>{t('DeleteClasses', { ns: 'classPage' })}</span>
          </button>
        </div>
        <Table sx={{ minWidth: 750 }} aria-labelledby='classesTable'>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox'>
                <Checkbox
                  color='primary'
                  indeterminate={selectedRowIds.length > 0 && selectedRowIds.length < rows.length}
                  checked={rows.length > 0 && selectedRowIds.length === rows.length}
                  onChange={(e) => handleSelectAllClick(e)}
                  label={undefined}
                />
              </TableCell>
              <TableCell sx={{ width: 500 }}>{t('ClassName', { ns: 'organizationPage' })}</TableCell>
              <TableCell sx={{ width: 500 }}>{t('NumberOfStudents', { ns: 'classPage' })}</TableCell>
              <TableCell sx={{ width: 150 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row.id);

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row.id)}
                  role='checkbox'
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox color='primary' checked={isItemSelected} label={undefined} />
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.numberOfStudents}</TableCell>
                  <TableCell className='buttons-cell'>
                    <button
                      className='row-button'
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRowIds([row.id]);
                        cs.editedClass = { ...cs.editedClass, id: row.id, name: row.name };
                        setEditClassModalOn(true);
                      }}
                    >
                      <PencilIcon />
                    </button>
                    <button
                      className='row-button'
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRowIds([row.id]);
                        cs.addToSelectedClassId = row.id;
                        setSelectedClassId(row.id);
                        setIsAddStudentsModalOn(true);
                      }}
                    >
                      <AddStudentsIcon />
                    </button>
                    <button
                      className='row-button'
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRowIds([row.id]);
                        setIsDeleteClassesModalOn(true);
                      }}
                    >
                      <TrashIcon />
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <RenameClassModal isModalOn={isEditClassModalOn} setIsModalOn={setEditClassModalOn} />
      <AddStudentsToClassModal isModalOn={isAddStudentsModalOn} setIsModalOn={setIsAddStudentsModalOn} selectedClassId={selectedClassId} />
      <DeleteClassesModal isModalOn={isDeleteClassesModalOn} setIsModalOn={setIsDeleteClassesModalOn} selected={selectedRowIds} />
    </div>
  );
});

export default ClassesList;
