import './ProfileInput.scss';

import { ReactComponent as ErrorIcon } from 'images/icons/Error.svg';
import { Input } from '@trackman/web-shared-components';
import i18next from 'i18next';
import { observer } from 'mobx-react-lite';

type ProfileInputProps = {
  label: string;
  value: unknown;
  onChange: (newValue: string) => void;
  type?: string;
  helperText?: string;
  error?: boolean;
  errorMsg?: string;
  className?: string;
  required?: boolean;
};

export const ProfileInput = observer(
  ({
    label,
    value,
    onChange,
    type,
    helperText,
    error,
    errorMsg = i18next.t('ThisFieldIsRequired', { ns: 'general' }),
    className,
    required,
  }: ProfileInputProps) => {
    return (
      <div className={`text-field ${className ? className : ''}`}>
        <Input
          id={label}
          label={label}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.currentTarget.value)}
          helperText={helperText}
          type={type}
          className={error ? 'input-error' : ''}
          required={required}
        />

        {error && (
          <p className='error-info'>
            <ErrorIcon />
            <span>{errorMsg}</span>
          </p>
        )}
      </div>
    );
  }
);

export default ProfileInput;
