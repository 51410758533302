import './ParameterMedia.scss';

import ImageUploader from 'components/Admin/ImageUploader/ImageUploader';
import Paragraph from 'components/TextComps/Paragraph';
import { ParameterMediaType } from 'types/admin/trackmanParameters';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { Select } from '@trackman/web-shared-components';
import { observer } from 'mobx-react-lite';
import { threeDModelUrlValidator } from 'utilities/urlValidators';
import { useCallback } from 'react';
import { useStores } from 'index';

type Props = {
  setThreeDModelUrlError: (value: boolean) => void;
  threeDModelUrlError: boolean;
};

const mediaTypes = [
  { label: 'Image', value: 'Image' },
  { label: '3D Model', value: 'ThreeDModel' },
];

export const ParameterMedia = observer(({ setThreeDModelUrlError, threeDModelUrlError }: Props) => {
  const ps = useStores().parametersStore;

  const onMediaTypeChange = useCallback(
    (mediaType: ParameterMediaType) => {
      ps.parameter.mediaType = mediaType;

      if (mediaType === 'ThreeDModel') {
        delete ps.parameter.mainImgData;
        ps.parameter.threeDModelUrl = '';
        ps.parameter.legendData = [];
      } else {
        delete ps.parameter.threeDModelUrl;
        ps.parameter.legendData = [];
      }
    },
    [ps.parameter]
  );

  const handleOnChange = useCallback(
    (newValue: string) => {
      setThreeDModelUrlError(false);
      if (!threeDModelUrlValidator(newValue)) {
        setThreeDModelUrlError(true);
      }
      ps.parameter.threeDModelUrl = newValue;
    },
    [ps.parameter, setThreeDModelUrlError]
  );

  const handleUploadImage = useCallback(
    (data: File) => {
      ps.isMainImgChanged = true;
      ps.parameter.mainImgData = {
        id: '',
        data: data,
      };
    },
    [ps]
  );

  return (
    <div className='admin-edit-section'>
      <h2>Parameter media</h2>
      {ps.parameter.mediaType === 'ThreeDModel' && (
        <Paragraph>
          <span className={threeDModelUrlError ? 'error' : ''}>
            Please add only URL-s for models created in <strong>https://spline.design/</strong> service with a{' '}
            <strong>scene.splinecode</strong> format.
          </span>
        </Paragraph>
      )}
      <Select
        label='Media Type'
        className='text-field'
        value={ps.parameter.mediaType ?? ''}
        onChange={(event) => {
          const selectedMediaType = mediaTypes.find((type) => type.value === event.target.value);
          if (selectedMediaType) {
            onMediaTypeChange(selectedMediaType.value as ParameterMediaType);
          }
        }}
        selectOptions={mediaTypes.map((mediaType) => {
          return {
            label: mediaType ? mediaType.label : '',
            value: mediaType ? mediaType.value : '',
          };
        })}
      />
      <div className='parameter-media'>
        {ps.parameter.mediaType === 'Image' ? (
          <ImageUploader
            title='Main parameter image'
            imageData={ps.parameter.mainImgData}
            imageUrl={ps.parameter.mainImgUrl}
            isSaving={ps.isSaving}
            handleUploadImage={handleUploadImage}
          />
        ) : (
          <ProfileInput
            className={threeDModelUrlError ? 'error' : ''}
            label='3D Model URL'
            value={ps.parameter.threeDModelUrl ?? ''}
            onChange={(newValue) => handleOnChange(newValue)}
          />
        )}
      </div>
    </div>
  );
});

export default ParameterMedia;
