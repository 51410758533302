import { Button, Select } from '@trackman/web-shared-components';
import { DexterityValue, dexterityOptionToValue } from 'types/profile';

import { ReactComponent as TourPlayerIcon } from 'images/icons/TourPlayer.svg';
import i18next from 'i18next';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

const handicapOptions = [...Array(42).keys()]
  .map((i) => i - 5)
  .map((integer) => {
    return {
      label: integer < 0 ? `+${integer * -1}` : integer === 36 ? '36+' : integer.toString(),
      value: integer.toString(),
    };
  });

const unitSystemOptions = [
  { label: i18next.t('Imperial', { ns: 'userCulture' }), value: 'en-US' },
  { label: i18next.t('Japanese Units', { ns: 'userCulture' }), value: 'ja-JP' },
  { label: i18next.t('Korean Units', { ns: 'userCulture' }), value: 'ko-KR' },
  { label: i18next.t('Metric', { ns: 'userCulture' }), value: 'en-GB' },
];

const categories = [
  { label: i18next.t('TeachingPro', { ns: 'editProfile' }), value: 'Teaching Pro' },
  { label: i18next.t('TourPro', { ns: 'editProfile' }), value: 'Tour Pro' },
  { label: i18next.t('ClubfitterRetail', { ns: 'editProfile' }), value: 'Clubfitter / Retail' },
  { label: i18next.t('EQM', { ns: 'editProfile' }), value: 'EQM' },
  { label: i18next.t('Amateur', { ns: 'editProfile' }), value: 'Amateur' },
  { label: i18next.t('Student', { ns: 'editProfile' }), value: 'Student' },
  { label: i18next.t('Other', { ns: 'editProfile' }), value: 'Other' },
];

export const PlayerSettingsTab = observer(() => {
  const ps = useStores().profileStore;
  const { t } = useTranslation();

  return (
    <div className='profile-section'>
      <div className='profile-inputs-column'>
        <div className='profile-inputs-column-title'>
          <TourPlayerIcon />
          {t('PlayerInformation', { ns: 'editProfile' })}
        </div>
        <Select
          label={t('Dexterity', { ns: 'editProfile' })}
          value={ps.profileData.dexterity ?? ''}
          className='text-field'
          onChange={(event) => {
            ps.profileData.dexterity = event.target.value as DexterityValue;
          }}
          selectOptions={Object.entries(dexterityOptionToValue).map((option) => {
            return {
              label: option[0],
              value: option[1],
            };
          })}
        />
        <Select
          label={t('Handicap', { ns: 'editProfile' })}
          value={ps.profileData.handicap.toString() ?? ''}
          className='text-field'
          onChange={(event) => (ps.profileData.handicap = Number.parseFloat(event.target.value as string))}
          selectOptions={handicapOptions}
        />
        <Select
          label={t('PlayerCategory', { ns: 'editProfile' })}
          value={ps.profileData.category ?? ''}
          className='text-field'
          onChange={(event) => (ps.profileData.category = event.target.value as string)}
          selectOptions={categories}
        />
      </div>
      <div className='profile-inputs-column no-subtitle'>
        <Select
          label={t('UnitSystem', { ns: 'editProfile' })}
          value={ps.profileData.culture ?? ''}
          className='text-field'
          onChange={(event) => (ps.profileData.culture = event.target.value as string)}
          selectOptions={unitSystemOptions}
        />
        <Select
          label={t('Nationality', { ns: 'editProfile' })}
          value={ps.profileData.nationality ?? ' ' ?? ''}
          className='text-field'
          onChange={(event) => (ps.profileData.nationality = event.target.value as string)}
          selectOptions={ps.signUpLists.Countries.map((country) => {
            return {
              label: country.name === ' ' ? 'Please select a country' : country.name,
              value: country.name,
            };
          })}
        />
        <div className='button-wrapper'>
          <Button
            className='styled-button primary'
            label={t('SaveChanges', { ns: 'editProfile' })}
            onClick={() => {
              ps.saveProfile();
            }}
            intent='primary'
            isLoading={ps.isSavingProfile}
            disabled={ps.isLoading || ps.isSavingProfile}
          />
        </div>
      </div>
    </div>
  );
});

export default PlayerSettingsTab;
