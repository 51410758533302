import { Select } from '@trackman/web-shared-components';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type Props = {
  onChange: (value: string, e?) => void;
  value: string;
};

export const SelectClass = observer(({ onChange, value }: Props) => {
  const { t } = useTranslation();
  const cs = useStores().classesStore;

  return (
    <Select
      label={t('SelectClass', { ns: 'organizationPage' })}
      value={value ?? ''}
      className='text-field'
      onChange={(event) => onChange(event.target.value as string)}
      selectOptions={cs.classesList.map((classItem) => {
        return {
          label: classItem.name === ' ' ? t('PleaseSelectClass', { ns: 'organizationPage' }) : classItem.name,
          value: classItem.id,
        };
      })}
    />
  );
});

export default SelectClass;
