import './LandingPage.scss';

import { Button } from '@trackman/web-shared-components';
import CertificationsStack from 'components/HomepageStacks/CertificationsStack';
import ModulesAndTestsStack from 'components/HomepageStacks/ModulesAndTestsStack';
import { OfferDescriptionItem } from 'components/OfferDescriptionItem/OfferDescriptionItem';
import Paragraph from 'components/TextComps/Paragraph';
import SectionTitle from 'components/TextComps/SectionTitle';
import Subtitle from 'components/TextComps/Subtitle';
import { ReactComponent as TMLogo } from '../../images/MainMenu/Trackman1.svg';
import heroPhoto from 'images/LandingPage/CoachAndPlayer.jpg';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const LandingPage = observer(() => {
  const as = useStores().authenticationService;
  const { t } = useTranslation();

  useSetPageTitle({
    title: t('TrackmanUniversityFreeGolfEducation', { ns: 'general' }),
    subtitle: '',
  });

  return (
    <div className='landing-page-container'>
      <div className='landing-page-hero-container'>
        <img className='landing-page-hero' src={heroPhoto} alt='Coach and Player at golf field' />
        <div className='landing-page-hero-overlay'>
          <div className='logo-container'>
            <TMLogo />
          </div>
          <div className='hero-content'>
            <h1 className='hero-title'>{t('UnleashPotential', { ns: 'landingPage' })}</h1>
            <div className='hero-subtitle'>{t('CompleteYourGolfKnowledge', { ns: 'landingPage' })}</div>
            <div>
              <Button
                label={t('LogIn', { ns: 'landingPage' })}
                intent='primary'
                onClick={() => as.logIn()}
                className='login-btn styled-button primary'
                isLoading={as.isLoggingIn}
                disabled={as.isLoggingIn}
              />
            </div>
          </div>
          {/* empty div of the same dimensions as logo to align in flex */}
          <div className='logo-container' />
        </div>
      </div>
      <div className='landing-page-sections'>
        <div className='landing-page-section is-gray'>
          <div className='container'>
            <div>
              <ModulesAndTestsStack />
            </div>
            <div className='text-gap'>
              <SectionTitle>
                <>{t('ModulesAndTests', { ns: 'general' })}</>
              </SectionTitle>
              <Paragraph>
                <>{t('HowDoIIncreaseSmashFactor', { ns: 'landingPage' })}</>
              </Paragraph>
            </div>
          </div>
        </div>
        <div className='landing-page-section'>
          <div className='container md-reversed'>
            <div>
              <CertificationsStack />
            </div>
            <div className='text-gap'>
              <SectionTitle>
                <>{t('TrackmanCertifications', { ns: 'landingPage' })}</>
              </SectionTitle>
              <Paragraph>
                <>{t('BeingTrackmanCertifiedHasManyUpsides', { ns: 'landingPage' })}</>
              </Paragraph>
            </div>
          </div>
        </div>
        <div className='landing-page-section is-gray'>
          <div className='container md-reversed'>
            <div>
              <div className='basic-offer'>
                <Subtitle>
                  <>{t('Basic', { ns: 'aboutCertification' })}</>
                </Subtitle>
                <SectionTitle>
                  <>{t('Free', { ns: 'aboutCertification' })}</>
                </SectionTitle>
                <div className='offer-description'>
                  <OfferDescriptionItem text={t('TrackmanDataParameterDefinitions', { ns: 'aboutCertification' })} />
                  <OfferDescriptionItem text={t('LearningMaterialsThatHelpYou', { ns: 'aboutCertification' })} />
                  <OfferDescriptionItem text={t('AccessToAllAdaptive', { ns: 'aboutCertification' })} />
                </div>
              </div>
            </div>
            <div className='text-gap'>
              <SectionTitle>
                <>{t('StartFreeOfCharge', { ns: 'landingPage' })}</>
              </SectionTitle>
              <Paragraph>
                <>{t('BasicSubscriptionIsFreeOfCharge', { ns: 'landingPage' })}</>
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default LandingPage;
