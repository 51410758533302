import { AdminCourseModel } from 'types/admin/courses';
import { AdminLessonModel } from 'types/admin/lessons';
import { AdminParameterModel } from 'types/admin/trackmanParameters';
import { Checkbox } from '@trackman/web-shared-components';
import { StudyRoomLessonModel } from 'types/admin/studyroomLessons';
import { observer } from 'mobx-react-lite';

type Props = {
  dataItem: AdminLessonModel | AdminCourseModel | StudyRoomLessonModel | AdminParameterModel;
};

export const VisibleSetting = observer(({ dataItem }: Props) => {
  return <Checkbox label='Visible' reversed onChange={() => (dataItem.visible = !dataItem.visible)} checked={dataItem.visible} />;
});

export default VisibleSetting;
