import '../../Admin.scss';
import '../../../../styles/_table.scss';

import { Table, TableBody, TableCell, TableHead, TableRow } from '@trackman/web-shared-components';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { DefaultRoutes } from 'Routes';
import DeleteListItemModal from 'components/Admin/Modals/DeleteListItemModal/DeleteListItemModal';
import { Link } from 'react-router-dom';
import Paragraph from 'components/TextComps/Paragraph';
import { PartnerOrMaster } from 'types/partner';
import { ReactComponent as PencilIcon } from 'images/icons/Pencil.svg';
import { ReactComponent as PlusIcon } from 'images/icons/Plus.svg';
import { ReactComponent as SearchIcon } from 'images/icons/Search.svg';
import { ReactComponent as TrashIcon } from 'images/icons/Trash.svg';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

type RowsDisplay = {
  id: string;
  fullName: string;
};

export const MastersList = observer(() => {
  useSetPageTitle({ title: 'Masters List', subtitle: '' });
  const ms = useStores().mastersStore;
  const mes = useStores().mastersEditorStore;
  const [searchValue, setSearchValue] = useState('');
  const [isModalOn, setIsModalOn] = useState(false);

  useEffect(() => {
    ms.getMasters();
  }, [ms]);

  useEffect(() => {
    if (typeof ms.masters[0] !== 'number' && 'fullName' in ms.masters[0]) {
      ms.filteredList = (ms.masters as PartnerOrMaster[]).filter((master) =>
        master.fullName.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
  }, [ms, searchValue]);

  const rowsDisplay: RowsDisplay[] = useMemo(
    () =>
      ms.filteredList.map((master) => {
        return {
          id: master.id,
          fullName: master.fullName,
        };
      }) as RowsDisplay[],
    [ms.filteredList]
  );

  const deleteMaster = useCallback(() => {
    mes.selectedMaster.id && mes.deleteMaster(mes.selectedMaster.id);
    setIsModalOn(false);
  }, [mes, setIsModalOn]);

  return (
    <div className='admin-list-container admin-content-container'>
      <div className='admin-list-header'>
        <div className='admin-list-header-searchbar'>
          <div className='text-field search-field'>
            <input
              className='search-input'
              placeholder={'Search'}
              name='search-for-master'
              value={searchValue}
              onChange={(e) => setSearchValue(e.currentTarget.value)}
            />
            <SearchIcon />
          </div>
        </div>
        <div className='admin-list-header-button'>
          <Link to={`/Admin/${DefaultRoutes.MastersEditor}`} className='styled-button primary link-button'>
            <PlusIcon />
            <span>New master</span>
          </Link>
        </div>
      </div>

      <div className='tmu-table-wrapper'>
        <Table sx={{ minWidth: 750 }} className='tmu-table admin-table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsDisplay.length ? (
              rowsDisplay.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell>{row.fullName.toLowerCase()}</TableCell>
                    <TableCell>
                      <div className='action-buttons'>
                        <Link to={`/Admin/${DefaultRoutes.MastersEditor}/${row.id}`}>
                          <PencilIcon />
                        </Link>
                        <button
                          className={`row-button ${mes.isLoading ? 'disabled' : ''}`}
                          onClick={() => {
                            mes.selectedMaster.id = row.id;
                            setIsModalOn(true);
                          }}
                          disabled={mes.isLoading}
                        >
                          <TrashIcon />
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <Paragraph>
                    <>No masters found</>
                  </Paragraph>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DeleteListItemModal isModalOn={isModalOn} setIsModalOn={setIsModalOn} deleteAction={deleteMaster} itemName='master' />
    </div>
  );
});

export default MastersList;
