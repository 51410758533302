import './ApplyTab.scss';

import { Trans, useTranslation } from 'react-i18next';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ReactComponent as ArrowDownIcon } from 'images/icons/Icon_Left.svg';
import Paragraph from 'components/TextComps/Paragraph';
import SectionTitle from 'components/TextComps/SectionTitle';
import Subtitle from 'components/TextComps/Subtitle';
import { observer } from 'mobx-react-lite';

export const ApplyTab = observer(() => {
  const { t } = useTranslation();

  return (
    <div className='apply-tab-container'>
      <div className='what-it-takes-tab'>
        <div className='text-gap'>
          <SectionTitle>
            <>{t('WhatItTakesToBecomeATrackmanUniversityMaster', { ns: 'applyForMaster' })}</>
          </SectionTitle>
          <Subtitle>
            <>{t('ToBecomeATrackmanUniversityMasterYouMust', { ns: 'applyForMaster' })}</>
          </Subtitle>
          <ul>
            <li>{t('BeATrackmanUniversityMemberInGoodStanding', { ns: 'applyForMaster' })}</li>
            <li>{t('PassTheTmuLevelI', { ns: 'applyForMaster' })}</li>
            <li>{t('Earn15TrackmanUniversityCredits', { ns: 'applyForMaster' })}</li>
            <li>{t('Submit5QuestionsThatCouldBeUsedOnTheTmuLevel', { ns: 'applyForMaster' })}</li>
          </ul>
          <Paragraph>
            <>{t('NoteTrackmanUniversityMasterStatus', { ns: 'applyForMaster' })}</>
          </Paragraph>
        </div>
      </div>
      <div className='tmu-credits'>
        <div className='text-gap'>
          <SectionTitle>
            <>{t('TrackmanUniversityCredits', { ns: 'applyForMaster' })}</>
          </SectionTitle>
          <Paragraph>
            <Trans i18nKey='AMinimumOf15Tmcs' ns='applyForMaster'>
              <span className='no-margin'>
                A minimum of 15 TMCs must be earned within 1 year from initiating the certification process.
              </span>
              <span className='no-margin'>Re-certification is required every 3 years - see details below.</span>
            </Trans>
          </Paragraph>
          <Paragraph>
            <Trans i18nKey='AllCommunicationIsDoneThrough' ns='applyForMaster'>
              <span>
                All communication is done through&nbsp;<a href='mailto:master@trackmanuniversity.com'>master@trackmanuniversity.com</a>
              </span>
            </Trans>
          </Paragraph>
        </div>
      </div>
      <div className='list-of-options-wrapper'>
        <SectionTitle>
          <>{t('TheFollowingIsACurrentListOfOptionsForEarningTmcs', { ns: 'applyForMaster' })}</>
        </SectionTitle>
        <div className='list-of-options'>
          <Accordion>
            <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls={'option-1-content'}>
              <Trans i18nKey='Submit1ScreencastsThatIncludeAnAnalysis' ns='applyForMaster'>
                <Paragraph className='tmcs-title'>
                  <>Submit 1 screencast that include an analysis of the data</>
                </Paragraph>
                <Paragraph className='tmcs-value'>
                  <>3 TMCS</>
                </Paragraph>
              </Trans>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Trans i18nKey='ScreencastsMustBeSharedThroughTheTpsSoftware' ns='applyForMaster'>
                    Screencasts must be shared via email to&nbsp;
                    <a href='mailto:master@trackmanuniversity.com'>master@trackmanuniversity.com</a>.
                  </Trans>
                </li>
                <li>{t('String1ScreencastsMustIncludeAtLeastOneCameraAngle', { ns: 'applyForMaster' })}</li>
                <li>{t('ScreencastMustDescribeTheCauseAndEffectBetweenTheData', { ns: 'applyForMaster' })}</li>
                <li>{t('ScreencastMustAnalyzeTheSubjectsSwingAndHowItRelates', { ns: 'applyForMaster' })}</li>
                <li>{t('ScreencastMustProvideAnExplanationOfAFaultAndFix', { ns: 'applyForMaster' })}</li>
                <li>{t('ScreencastMustProvideAnInDepthAnalysis', { ns: 'applyForMaster' })}</li>
                <li>{t('TheTargetAudienceOfTheScreencastIsTrackmanMasterCertifiedCoach', { ns: 'applyForMaster' })}</li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls={'option-2-content'}>
              <Trans i18nKey='Submit1TrackmanCombinedCaseStudies' ns='applyForMaster'>
                <Paragraph className='tmcs-title'>
                  <>Submit 1 Trackman Combined case studies</>
                </Paragraph>
                <Paragraph className='tmcs-value'>
                  <>3 TMCS</>
                </Paragraph>
              </Trans>
            </AccordionSummary>
            <AccordionDetails>
              <Paragraph>
                <>{t('EachTrackmanCombineCaseStudyShouldBeApproximately', { ns: 'applyForMaster' })}</>
              </Paragraph>
              <ul>
                <li>{t('ResultsOfASubjectsTrackmanCombine', { ns: 'applyForMaster' })}</li>
                <li>{t('ABriefAnalysisOfTheDataResults', { ns: 'applyForMaster' })}</li>
                <li>{t('CreateAMinimumOfOneTestCenterProtocol', { ns: 'applyForMaster' })}</li>
                <li>{t('DescribeTheGoalsSetWithTheSubjectToAchieve', { ns: 'applyForMaster' })}</li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls={'option-3-content'}>
              <Trans i18nKey='SubmitAVideoOrAnArticle' ns='applyForMaster'>
                <Paragraph className='tmcs-title'>
                  <>Submit a video or an article that provides an educational value to the Trackman community</>
                </Paragraph>
                <Paragraph className='tmcs-value'>
                  <>6 or 9 TMCS</>
                </Paragraph>
              </Trans>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>{t('TheVideoOrArticleIsSubjectToBeUsedInTrackmanChannels', { ns: 'applyForMaster' })}</li>
                <li>{t('AMaximumOf9TMCsCanBeEarnedWithinTheCurrentPeriod', { ns: 'applyForMaster' })}</li>
                <li>{t('NineTMCsWillBeAwarded', { ns: 'applyForMaster' })}</li>
                <li>
                  <Trans i18nKey='ToReceivePreapprovalOfATopicOrIdea' ns='applyForMaster'>
                    Topic or idea must be pre-approved. To receive preapproval of a topic or idea, send an email describing the idea/topic
                    to&nbsp;<a href='mailto:master@trackmanuniversity.com'>master@trackmanuniversity.com</a>. Include information about the
                    format (video/article), purpose/goal of the submission, and description of how data/information will be collected.
                  </Trans>
                </li>
              </ul>
              <Paragraph className='italic'>
                <>{t('TmuMayRequestEditorialChangesPriorToTheTmcsBeingAwardedForTheContent', { ns: 'applyForMaster' })}</>
              </Paragraph>
              <Paragraph>
                <>{t('ToReceiveTheTmcsTheIdeaConceptMustBeThoughtThrough', { ns: 'applyForMaster' })}</>
              </Paragraph>
              <ul>
                <li>
                  <Trans i18nKey='ToReceivePreapprovalOfAnIdeaOrConcept' ns='applyForMaster'>
                    To receive preapproval of an idea or concept, send an email describing the idea/concept to&nbsp;
                    <a href='mailto:master@trackmanuniversity.com'>master@trackmanuniversity.com</a>.
                  </Trans>
                </li>
                <li>{t('TmuMayRequestEditorialChangesPriorToTheTmcsBeingAwardedForTheIdeaConcept', { ns: 'applyForMaster' })}</li>
                <li>{t('PresentTheIdeaConceptAndFunctionalityInAStepByStep', { ns: 'applyForMaster' })}</li>
              </ul>
              <Paragraph className='italic'>
                <>{t('NoteSubmissionOfAnIdeaConceptGrantsTrackmanTheRight', { ns: 'applyForMaster' })}</>
              </Paragraph>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls={'option-4-content'}>
              <Trans i18nKey='PresentingOrActivelyPromoting' ns='applyForMaster'>
                <Paragraph className='tmcs-title'>
                  <>Presenting or actively promoting Trackman technology</>
                </Paragraph>
                <Paragraph className='tmcs-value'>
                  <>3 TMCS</>
                </Paragraph>
              </Trans>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>{t('TheContentCanBeHostedByTrackmanOrOutside', { ns: 'applyForMaster' })}</li>
                <li>{t('TheVideoOrArticleIsSubjectToBeUsedInTrackmanChannels', { ns: 'applyForMaster' })}</li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls={'option-5-content'}>
              <Trans i18nKey='SoMeActivity' ns='applyForMaster'>
                <Paragraph className='tmcs-title'>
                  <>SoMe activity</>
                </Paragraph>
                <Paragraph className='tmcs-value'>
                  <>3 TMCS</>
                </Paragraph>
              </Trans>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>{t('ShareMinimum3PostsInSocialMedia', { ns: 'applyForMaster' })}</li>
                <li>{t('PostsAreToBePreApprovedByTMU', { ns: 'applyForMaster' })}</li>
              </ul>
              <Paragraph className='italic'>
                <>{t('NOTETrackmanHasTheRight', { ns: 'applyForMaster' })}</>
              </Paragraph>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls={'option-6-content'}>
              <Trans i18nKey='MandatoryTrackmanUniversityQuestions' ns='applyForMaster'>
                <p className='tm-paragraph tmcs-title'>
                  Mandatory Trackman University Questions - <span>Performance Putting Modules</span>
                </p>
                <Paragraph className='tmcs-value'>
                  <>3 TMCS</>
                </Paragraph>
              </Trans>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Trans i18nKey='SubmitFiveQuestionsTo' ns='applyForMaster'>
                    Submit 5 questions to <a href='mailto:master@trackmanuniversity.com'>master@trackmanuniversity.com</a> that could be
                    used on the Trackman Performance Putting certification test
                  </Trans>
                </li>
                <li>{t('Include5QuestionsWithMultipleChoiceAnswers', { ns: 'applyForMaster' })}</li>
                <li>{t('IncludeAnyImagesOrAssumptionsNeeded', { ns: 'applyForMaster' })}</li>
                <li>{t('IndicateTheCorrectAnswerForEachQuestion', { ns: 'applyForMaster' })}</li>
                <li>{t('ThreeSentencesPerQuestionsDescribing', { ns: 'applyForMaster' })}</li>
              </ul>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <div className='additional-info'>
        <div className='text-gap'>
          <SectionTitle>
            <>{t('AdditionalInformation', { ns: 'applyForMaster' })}</>
          </SectionTitle>
          <Paragraph>
            <Trans i18nKey='TrackmanMasterApplicantsMayBeAwarded' ns='applyForMaster'>
              Trackman Master applicants may be awarded credits for items not listed above. These credits are awarded at the sole discretion
              of Trackman University. Master applicants may apply for credits by sending a detailed description of the item in question
              to&nbsp;<a href='mailto:master@trackmanuniversity.com'>master@trackmanuniversity.com</a>
            </Trans>
          </Paragraph>
          <Paragraph>
            <>{t('UponReceivingTheTrackmanMasterDesignation', { ns: 'applyForMaster' })}</>
          </Paragraph>
          <Paragraph>
            <>{t('TheTmuMasterMustAccrue', { ns: 'applyForMaster' })}</>
          </Paragraph>
          <Paragraph>
            <Trans i18nKey='IfYouHaveAnyQuestionsAboutTheTrackman' ns='applyForMaster'>
              If you have any questions about the Trackman University Master application process, please reach out to&nbsp;
              <a href='mailto:master@trackmanuniversity.com'>master@trackmanuniversity.com</a>
            </Trans>
          </Paragraph>
        </div>
      </div>
      <div className='good-luck-tab'>
        <SectionTitle>
          <>{t('ThankYouAndGoodLuck', { ns: 'applyForMaster' })}</>
        </SectionTitle>
      </div>
    </div>
  );
});

export default ApplyTab;
