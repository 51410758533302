import { useCallback, useEffect, useRef } from 'react';

import { Button } from '@trackman/web-shared-components';
import Paragraph from 'components/TextComps/Paragraph';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { ReactComponent as TrashIcon } from 'images/icons/Trash.svg';
import { getLanguageText } from 'utilities/languages';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

export const ParameterLegendMedia = observer(() => {
  const ps = useStores().parametersStore;
  const imgInputRef = useRef<HTMLInputElement>(null);

  const uploadNewPic = useCallback(() => {
    if (imgInputRef.current?.files?.[0]) {
      ps.newLegendImage = {
        id: '',
        data: imgInputRef.current?.files[0],
      };
      ps.uploadLegendImage();
    }
  }, [ps]);

  useEffect(() => {
    ps.getLegendImagesCollection();
  }, [ps]);

  return (
    <div className='admin-edit-section'>
      <div className='parameter-media'>
        <Paragraph>
          <>Select legend images</>
        </Paragraph>
        <div className='legend-images'>
          {ps.legendImgCollection.map((legendCollectionImage, index) => {
            const foundImg = ps.parameter.legendData.find(
              (parameterLegend) => parameterLegend.legendImageUrl === legendCollectionImage.url
            );
            return (
              <div
                key={`legend-image-${index}`}
                className='legend-image'
                onClick={() =>
                  foundImg
                    ? ps.parameter.legendData.slice(index, 1)
                    : ps.parameter.legendData.push({ legendImageUrl: legendCollectionImage.url, legendDescriptions: [] })
                }
              >
                <img src={legendCollectionImage.url} alt={legendCollectionImage.name} />
              </div>
            );
          })}
          <div className='inputs-container'>
            <input type='file' id='file' accept='image/*' ref={imgInputRef} style={{ display: 'none' }} onChange={uploadNewPic} />
            <Button className='styled-button secondary' label='Add' intent='secondary' onClick={() => imgInputRef.current?.click()} />
          </div>
        </div>
      </div>
      <div className='added-legend-items'>
        {ps.parameter.legendData.map((legend, index) => {
          return (
            <div key={`legend-item-${legend.legendImageUrl}`} className='legend-item'>
              <div className='legend-title'>
                <Paragraph>
                  <>Legend image:</>
                </Paragraph>
                <div className='legend-image'>
                  <img src={legend.legendImageUrl} alt='legend' />
                </div>
                <button className='delete-button' onClick={() => ps.parameter.legendData.splice(index, 1)}>
                  <TrashIcon />
                </button>
              </div>

              <div className='inputs-container'>
                {ps.parameter.languages.map((language) => {
                  const foundDescription = legend.legendDescriptions.find((legendDesc) => legendDesc.countryCode === language);
                  return (
                    <ProfileInput
                      key={`${legend.legendImageUrl}-${language}`}
                      label={`${getLanguageText(language)} title`}
                      value={foundDescription?.text ?? ''}
                      onChange={(newValue) =>
                        foundDescription
                          ? (foundDescription.text = newValue)
                          : legend.legendDescriptions.push({ countryCode: language, text: newValue })
                      }
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default ParameterLegendMedia;
